import React, { useEffect, useState } from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import { ReactComponent as CrossIcon } from '../../../../../../assets/icons/app/cross.icon.svg'
import useAuthorAutocompleteHook from './useAuthorInputAutocompletionHook'
import { AuthorInterface } from '../../../../../../core/interface/author.interface'
import EditionsByAuthorCard from './EditionsByAuthorCard'
import AuthorsMenu from './AuthorsMenu'
import { BookInterface } from '../../../../../../core/interface/book.interface'

const InputStyle = styled.input`
  border-radius: 4px;
  border: 1px solid #e9e9e9;

  overflow: hidden;
  color: #929292;
  font-variant-numeric: lining-nums proportional-nums;
  text-overflow: ellipsis;
  font-family: Fira Sans, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px; /* 142.857% */

  padding: 10px;

  :focus {
    outline: none;
  }
`

const AuthorInputAutoCompletion = ({
  disable,
  color,
  inputValue,
  handleOnClikItem,
  booksSelected,
  handleUpdateBooksSelected,
}: {
  disable: boolean
  color: string
  inputValue: AuthorInterface[] | null
  handleOnClikItem: (author: AuthorInterface[]) => void
  booksSelected: BookInterface[]
  handleUpdateBooksSelected: (books: BookInterface[]) => void
}) => {
  const { t } = useTranslation()

  let timer: NodeJS.Timer | null = null
  const [menuOpen, setMenuOpen] = useState<boolean>(false)
  const [needCloseMenu, setNeedCloseMenu] = useState<boolean>(false)

  const [query, setQuery] = useState<string>('')
  const {
    state: { isLoadingGetAuthors, authors },
  } = useAuthorAutocompleteHook({ query })

  const onClickResetAuthorInput = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault()
    setQuery('')
  }

  const handleOnClickAuthorItem = (author: AuthorInterface) => {
    handleOnClikItem([...(inputValue || []), author])
    setQuery('')
    setMenuOpen(false)
  }

  const onChangeQueryValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value)
  }

  const handleFocusToInput = () => {
    setMenuOpen(true)
  }

  const handleBlurToInput = () => {
    setNeedCloseMenu(true)
  }

  const handleTimeOut = () => {
    timer = setTimeout(() => {
      setMenuOpen(false)
      setNeedCloseMenu(false)
    }, 300)
  }

  useEffect(() => {
    if (needCloseMenu) {
      handleTimeOut()
    }
    return () => {
      if (timer) {
        clearTimeout(timer)
      }
    }
  }, [needCloseMenu])

  return (
    <div className="flex-auto flex flex-col w-full gap-4">
      <div className="relative flex flex-col">
        <InputStyle
          type="text"
          placeholder={
            inputValue && inputValue.length
              ? t('ContinueAddAnAuthor')
              : t('SearchAnAuthor')
          }
          className="w-full"
          disabled={disable}
          value={query}
          onChange={onChangeQueryValue}
          onFocus={handleFocusToInput}
          onBlur={handleBlurToInput}
        />
        {(query && (
          <button
            type="button"
            className="p-2 absolute top-2 right-1"
            onClick={onClickResetAuthorInput}
          >
            <CrossIcon className="w-4 h-4" />
          </button>
        )) ||
          null}
      </div>
      <div
        className="relative flex flex-col w-full"
        style={{
          margin: '-8px 0',
        }}
      >
        {query && menuOpen && (
          <AuthorsMenu
            isLoading={isLoadingGetAuthors}
            authors={authors}
            handleOnClikItem={handleOnClickAuthorItem}
          />
        )}
      </div>
      {inputValue && (
        <div
          className="relative flex flex-auto"
          style={{
            overflow: 'hidden',
            margin: '0 -32px',
          }}
        >
          <div className="absolute w-full h-full">
            <PerfectScrollbar className="absolute flex flex-col h-full gap-2 px-8">
              {inputValue.map((input: AuthorInterface) => (
                <EditionsByAuthorCard
                  disable={disable}
                  author={input}
                  color={color}
                  booksSelected={booksSelected}
                  handleUpdateBooksSelected={handleUpdateBooksSelected}
                />
              ))}
            </PerfectScrollbar>
          </div>
        </div>
      )}
    </div>
  )
}

export default AuthorInputAutoCompletion
