import React from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { ClilMenuStyle } from '../../ReadershipProfileStyles'
import { AuthorInterface } from '../../../../../../core/interface/author.interface'
import AuthorItemMenu from './AuthorItemMenu'
import GlLoader from '../../../../../share/GlLoader'

const AuthorsMenu = ({
  isLoading,
  authors,
  handleOnClikItem,
}: {
  isLoading: boolean
  authors: AuthorInterface[]
  handleOnClikItem: (author: AuthorInterface) => void
}) => {
  return (
    <ClilMenuStyle
      className="absolute w-full flex flex-col top-0"
      style={{
        zIndex: 30,
      }}
    >
      {(isLoading && (
        <div className="flex-auto items-center justify-center p-2">
          <GlLoader width={36} height={36} />
        </div>
      )) ||
        (authors.length && (
          <PerfectScrollbar className="relative flex flex-col max-h-[264px] p-1">
            {authors.map((author: AuthorInterface) => (
              <AuthorItemMenu
                key={`author-${author.id}`}
                author={author}
                handleOnClikItem={handleOnClikItem}
              />
            ))}
          </PerfectScrollbar>
        )) || (
          <div className="flex items-center justify-center p-2">
            <span>Votre recherche ne propose pas de résultat</span>
          </div>
        )}
    </ClilMenuStyle>
  )
}

export default AuthorsMenu
