import { AnalyticsTypeEnum } from '../enums/analytics-type.enum'
import { AnalyticsStatusEnum } from '../enums/analytics-status.enum'

const getAnalyticColor = (
  type: AnalyticsTypeEnum,
  status?: AnalyticsStatusEnum,
): string => {
  switch (type) {
    case AnalyticsTypeEnum.WORK_MIRROR:
      return '#86daad'
    case AnalyticsTypeEnum.PRIVATE_WORK_MIRROR:
      return '#93e39a'
    case AnalyticsTypeEnum.READERSHIP_PROFILE:
      return status && status === AnalyticsStatusEnum.GENERATING
        ? '#666666'
        : '#7bbfff'
    case AnalyticsTypeEnum.PRIVATE_READERSHIP_PROFILE:
      return status && status === AnalyticsStatusEnum.GENERATING
        ? '#666666'
        : '#9ee1e3'
    case AnalyticsTypeEnum.AUDIENCE:
      return '#FFAC81'
    case AnalyticsTypeEnum.UNIVERS:
      return '#a3a6ff'
    default:
      return '#BEBEBE'
  }
}

export default getAnalyticColor
