import React from 'react'
import styled from '@emotion/styled'
import { AuthorBookStyle, TitleBookStyle } from '../../ReadershipProfileStyles'
import { BookSearchInterface } from '../../../../../../core/interface/book-search.interface'
import { ReactComponent as BookIcon } from '../../../../../../assets/icons/app/book.icon.svg'

const ContainerStyle = styled.div`
  background: ${({
    alreadySelected,
    isUnrecommendable,
  }: {
    alreadySelected?: boolean
    isUnrecommendable?: boolean
  }) => ((alreadySelected || isUnrecommendable) && '#e3e3e3') || '#ffffff'};
  cursor: ${({
    alreadySelected,
    isUnrecommendable,
  }: {
    alreadySelected?: boolean
    isUnrecommendable?: boolean
  }) => (alreadySelected || isUnrecommendable ? 'default' : 'pointer')};
`

const TitleItemMenu = ({
  book,
  inputValueEan,
  handleOnClikTitleItem,
}: {
  book: BookSearchInterface
  inputValueEan: number[]
  handleOnClikTitleItem: (book: BookSearchInterface) => void
}) => {
  const onClickItem = (event: React.MouseEvent) => {
    event.preventDefault()
    if (!inputValueEan?.includes(book.id_ean) && book.is_recommendable) {
      handleOnClikTitleItem(book)
    }
  }

  return (
    <ContainerStyle
      key={`book-search-item-${book.id_ean}`}
      className="flex-1 flex flex-row p-1 hover:bg-[#2525251a] rounded gap-2"
      alreadySelected={inputValueEan.includes(book.id_ean)}
      onClick={onClickItem}
      isUnrecommendable={!book.is_recommendable}
    >
      <div
        className="flex-none flex items-center justify-center rounded-sm bg-[#f7f7f7]"
        style={{
          width: 40,
          height: 40,
        }}
      >
        {(book.imgs && (
          <img
            className="rounded-sm"
            src={`${book.imgs}`}
            alt=""
            style={{
              minWidth: 40,
              maxWidth: 40,
              minHeight: 40,
              maxHeight: 40,
              objectFit: 'cover',
            }}
          />
        )) || (
          <BookIcon
            style={{
              width: 24,
              height: 24,
            }}
          />
        )}
      </div>

      <div className="flex-auto flex flex-col">
        <TitleBookStyle>{book.title}</TitleBookStyle>
        {(book.authors.length && (
          <AuthorBookStyle>{book.authors[0]}</AuthorBookStyle>
        )) ||
          null}
      </div>
    </ContainerStyle>
  )
}

export default TitleItemMenu
