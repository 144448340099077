import React from 'react'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import { ReactComponent as AddIcon } from '../../../assets/icons/app/add-icon-plain.icon.svg'
import { PaginationResponseInterface } from '../../../core/interface/pagination-response.interface'
import { ReportLightResponseInterface } from '../../../core/interface/report/report-light-response.interface'
import TableAnalytics from './TableAnalytics'
import AnalyticsDashboardAvailableModuleType from '../../../core/types/analytics-dashboard-available-module.interface'
import GlProBadge from '../../share/badge/GlProBadge'
import { AnalyticsTypeEnum } from '../../../core/enums/analytics-type.enum'
import AnalyticsDashboardType from '../../../core/enums/analytics-dashboard-type.enum'

const ButtonAddStyle = styled.button`
  color: #3b3b3b;
  font-family: Vollkorn, serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  background: #f7f7f7;
  height: 48px;
  width: 100%;
`
const AnalyticsDashboardListView = ({
  analytics,
  displayOnlyMyAnalytics,
  handleCreateNewAnalytics,
  handleClickAnalytics,
  analyticsTypeSelected,
  handleReloadAction,
  companyUserId,
  isPrivate,
}: {
  displayOnlyMyAnalytics: boolean
  analytics: PaginationResponseInterface<ReportLightResponseInterface> | null
  handleCreateNewAnalytics: () => void
  handleClickAnalytics: (id: string, type: AnalyticsTypeEnum) => void
  analyticsTypeSelected: AnalyticsDashboardAvailableModuleType
  handleReloadAction: () => void
  companyUserId: string
  isPrivate?: boolean
}) => {
  const { t } = useTranslation()

  const onClickCreateAnalytic = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault()
    handleCreateNewAnalytics()
  }
  return (
    <div className="flex-auto flex flex-col justify-start items-center mx-4 gap-4">
      <ButtonAddStyle
        type="button"
        className="flex flex-row items-center justify-center rounded"
        onClick={onClickCreateAnalytic}
      >
        <AddIcon
          style={{
            width: 16,
            height: 16,
          }}
        />
        <span className="mx-2">{t('createAnAnalytics')}</span>
        {isPrivate && <GlProBadge />}
      </ButtonAddStyle>
      {(analytics && analytics.count > 0 && (
        <TableAnalytics
          analytics={
            analytics?.results
              .filter((analytic: ReportLightResponseInterface) =>
                displayOnlyMyAnalytics
                  ? analytic.owner?.id === companyUserId
                  : analytic,
              )
              .filter((analytic: ReportLightResponseInterface) => {
                if (analyticsTypeSelected === AnalyticsDashboardType.ALL) {
                  return analytic
                }
                return `${analytic.type}` === `${analyticsTypeSelected}`
              }) || []
          }
          handleReloadAction={handleReloadAction}
          handleClickAnalytics={handleClickAnalytics}
          companyUserId={companyUserId}
        />
      )) ||
        null}
    </div>
  )
}

export default AnalyticsDashboardListView
