import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from '../../store/hooks'
import { BookInterface } from '../interface/book.interface'
import { WorkMirrorBookInterface } from '../interface/report/work-mirror-book.interface'
import { BookDetailResponseInterface } from '../interface/rank/book-detail.interface'
import { getBookByEanIdService } from '../api/services/bookmetrie/get-book-by-ean-id.service'
import { getBookByIdEditionsService } from '../api/services/bookmetrie/get-book-by-id-editions.service'
import { getB2bBookDetailsByEanIdService } from '../api/services/others/get-b2b-book-details-by-ean-id.service'
import getBookRecoService from '../api/services/bookmetrie/get-book-reco.service'
import { setShowSnackBar } from '../../store/reducers/web-app.reducer'

const useShowWorkProfileHook = (): {
  bookSelectedEan: number | null
  setBookSelectedEan: (ean: number | null) => void
  bookDetails: WorkMirrorBookInterface | null
  isLoadingBookDetails: boolean
  bookEditions: BookInterface[]
  isLoadingEditions: boolean
  bookB2BInfos: BookDetailResponseInterface | null
  isLoadingB2BInfos: boolean
  bookReco: BookInterface[]
  isLoadingReco: boolean
} => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const [bookSelectedEan, setBookSelectedEan] = useState<number | null>(null)

  const [bookDetails, setBookDetails] =
    useState<WorkMirrorBookInterface | null>(null)
  const [isLoadingBookDetails, setIsLoadingBookDetails] =
    useState<boolean>(false)

  const [bookEditions, setBookEditions] = useState<BookInterface[]>([])
  const [isLoadingEditions, setIsLoadingEditions] = useState<boolean>(false)

  const [bookB2BInfos, setBookB2BInfos] =
    useState<BookDetailResponseInterface | null>(null)
  const [isLoadingB2BInfos, setIsLoadingB2BInfos] = useState<boolean>(false)

  const [bookReco, setBookReco] = useState<BookInterface[]>([])
  const [isLoadingReco, setIsLoadingReco] = useState<boolean>(false)

  useEffect(() => {
    if (bookSelectedEan) {
      const fetchEditions = async () => {
        setIsLoadingEditions(true)
        try {
          const { data } = await getBookByIdEditionsService(bookSelectedEan)
          setBookEditions(data)
        } catch (e) {
          setBookEditions([])
        } finally {
          setIsLoadingEditions(false)
        }
      }

      const fetchB2BInfos = async () => {
        setIsLoadingB2BInfos(true)
        try {
          const { data } = await getB2bBookDetailsByEanIdService(
            bookSelectedEan,
          )
          setBookB2BInfos(data)
        } catch (e) {
          setBookB2BInfos(null)
        } finally {
          setIsLoadingB2BInfos(false)
        }
      }

      const fetchReco = async () => {
        setIsLoadingReco(true)
        try {
          const { data } = await getBookRecoService({
            eanIds: [bookSelectedEan],
            nbrecos: 10,
          })
          setBookReco(data)
        } catch (e) {
          setBookReco([])
        } finally {
          setIsLoadingReco(false)
        }
      }

      const fetchBookDetails = async () => {
        setIsLoadingBookDetails(true)
        try {
          const { data } = await getBookByEanIdService(bookSelectedEan)
          setBookDetails({
            book: data,
            id_ean: bookSelectedEan,
            popularity: 0,
          })

          void fetchEditions()
          void fetchB2BInfos()
          void fetchReco()
        } catch (e) {
          setBookSelectedEan(null)
          setBookDetails(null)
          dispatch(setShowSnackBar(t('snackbarMessage.errorMessage')))
        } finally {
          setIsLoadingBookDetails(false)
        }
      }

      void fetchBookDetails()
    } else {
      setBookDetails(null)
      setBookEditions([])
      setBookB2BInfos(null)
      setBookReco([])
    }
  }, [bookSelectedEan])

  return {
    bookSelectedEan,
    setBookSelectedEan,
    bookDetails,
    isLoadingBookDetails,
    bookEditions,
    isLoadingEditions,
    bookB2BInfos,
    isLoadingB2BInfos,
    bookReco,
    isLoadingReco,
  }
}

export default useShowWorkProfileHook
