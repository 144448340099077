import { CancelToken } from 'axios'
import apiInstance from '../../backend-api.setup'
import { ApiPathConfig } from '../../../config/ApiPathConfig'

const getBookRecoService = (
  params: { eanIds: number[]; nbrecos?: number },
  cancelToken?: CancelToken,
) => apiInstance.get(ApiPathConfig.BOOK_RECO_WITH_EANS(params), { cancelToken })

export default getBookRecoService
