import { StudyObjectGroupCompareEnum } from '../configs/StudiesObjectsCompareGroupTypesConfig'
import ClilInputAutoCompletion from './clil/ClilInputAutoCompletion'
import TitleInputAutoCompletion from './titles/TitleInputAutoCompletion'
import AuthorInputAutoCompletion from './authors/AuthorInputAutoCompletion'
import { AuthorInterface } from '../../../../../core/interface/author.interface'
import { BookSearchInterface } from '../../../../../core/interface/book-search.interface'
import { BookInterface } from '../../../../../core/interface/book.interface'

const InputTypeSwitchInput = <InputType, ValueType>({
  disable,
  color,
  type,
  handleSelectValue,
  inputValue,
  booksSelected,
  handleUpdateBooksSelected,
  handleOpenBookDetails,
  eanSelected,
}: {
  disable: boolean
  color: string
  type: StudyObjectGroupCompareEnum
  inputValue: ValueType
  handleSelectValue: (value: ValueType, booksToRemove?: BookInterface[]) => void
  booksSelected: BookInterface[]
  handleUpdateBooksSelected: (books: BookInterface[]) => void
  handleOpenBookDetails: (eanId: number | null) => void
  eanSelected: number | null
}) => {
  switch (type) {
    case StudyObjectGroupCompareEnum.CLIL:
      return (
        <ClilInputAutoCompletion
          color={color}
          disable={disable}
          inputValue={inputValue as string[]}
          handleSelectClil={(value: string[]) =>
            handleSelectValue(value as ValueType)
          }
        />
      )
    case StudyObjectGroupCompareEnum.TITLE:
      return (
        <TitleInputAutoCompletion
          disable={disable}
          color={color}
          inputValue={inputValue as BookSearchInterface[] | null}
          handleOnClikItemMenu={(
            value: BookSearchInterface[] | null,
            booksToRemove?: BookInterface[],
          ) => handleSelectValue(value as ValueType, booksToRemove)}
          booksSelected={booksSelected}
          handleUpdateBooksSelected={handleUpdateBooksSelected}
          handleOpenBookDetails={handleOpenBookDetails}
          eanSelected={eanSelected}
        />
      )
    case StudyObjectGroupCompareEnum.AUTHOR:
      return (
        <AuthorInputAutoCompletion
          disable={disable}
          color={color}
          inputValue={inputValue as AuthorInterface[]}
          handleOnClikItem={(value: AuthorInterface[]) =>
            handleSelectValue(value as ValueType)
          }
          booksSelected={booksSelected}
          handleUpdateBooksSelected={handleUpdateBooksSelected}
        />
      )
    default:
      return null
  }
}

export default InputTypeSwitchInput
