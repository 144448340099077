import React, { useState } from 'react'
import styled from '@emotion/styled'

const LabelStyle = styled.div`
  font-weight: 400;
  color: ${({ enable }: { enable: boolean }) =>
    enable ? '#666666' : '#66666666'};
  text-align: center;
  font-family: Fira Sans, sans-serif;
  font-size: 12px;
  font-style: normal;
  line-height: 16px;
  letter-spacing: 0.24px;
`

const BarreStyle = styled.div`
  height: 18px;
  width: 32px;
  border-radius: 24px;
  background: ${({
    enable,
    activeColor,
  }: {
    enable: boolean
    activeColor: string
  }) => (enable ? activeColor : '#bebebe')};
`

const CursorStyle = styled.div`
  position: absolute;
  width: 14px;
  height: 14px;
  border-radius: 10px;
  background: white;
  top: 2px;
  left: ${({ enable }: { enable: boolean }) => (enable ? '16px' : '2px')};
  &:hover {
    background: #f4f4f4;
  }
  &:active {
    background: #e3e3e3;
`

const GlSlideToggle = ({
  label,
  handleOnToggle,
  enable = false,
  activeColor = '#252525',
}: {
  label?: string
  handleOnToggle: (value: boolean) => void
  enable?: boolean
  activeColor?: string
}) => {
  const [enabled, setEnabled] = useState<boolean>(enable)
  const onClickToggle = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation()
    const newValue = !enabled
    handleOnToggle(newValue)
    setEnabled(newValue)
  }

  return (
    <div
      className="flex flex-row items-center justify-center gap-2"
      onClick={onClickToggle}
    >
      <div className="relative">
        <BarreStyle enable={enabled} activeColor={activeColor} />
        <CursorStyle enable={enabled} />
      </div>
      {(label && <LabelStyle enable={enabled}>{label}</LabelStyle>) || null}
    </div>
  )
}

export default GlSlideToggle
