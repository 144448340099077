import React, { useCallback, useEffect, useRef, useState } from 'react'
import dayjs from 'dayjs'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { useTranslation } from 'react-i18next'
import Masonry from 'masonry-layout'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks'
import { AnalyticOutputInterface } from '../../../../core/interface/analytics/analytic-output.interface'
import { selectAnalyticDetailsStateWorkMirrorBookDetailsCardValues } from '../../../../store/getters/analytics-details/analytic-details-v2.getters'
import {
  selectAnalyticDetailsReadershipProfileStateCompareGroupBooks,
  selectAnalyticDetailsReadershipProfileStateCompareGroupType,
  selectAnalyticDetailsReadershipProfileStateCompareGroupValue,
  selectAnalyticDetailsReadershipProfileStateIsLoadingReadershipProfileInput,
  selectAnalyticDetailsReadershipProfileStateStudyObjectBooks,
  selectAnalyticDetailsReadershipProfileStateStudyObjectType,
  selectAnalyticDetailsReadershipProfileStateStudyObjectValue,
} from '../../../../store/getters/analytics-details/analytics-details-readership-profile-state.gettters'
import { analyticsDetailsReadershipProfileStateGetPreviewValue } from '../../../../store/actions/analytics-details/analytics-details-readership-profile-state.actions'
import { SetColorConfig } from '../../../../core/config/SetColorConfig'
import { analyticDetailsV2StateGetBookDetailsDataById } from '../../../../store/actions/analytics-details/analytic-details-v2.actions'
import GlLoader from '../../../share/GlLoader'
import RecapInputTag from '../../../analytics-details-v2/view/readership-profile/inputs/recap/RecapInputTag'
import {
  CompareGroupType,
  StudyObjectType,
} from '../../../analytics-details-v2/view/readership-profile/configs/StudiesObjectsCompareGroupTypesConfig'
import {
  CompareGroupValueType,
  StudyObjectValueType,
} from '../../../../store/interfaces/analytics-details/new-readership-profile-state.interface'
import OutputTypeSwitch from '../../../analytics-details-v2/view/readership-profile/outputs/OutputTypeSwitch'
import GlSideBar from '../../../share/GlSideBar'
import WorkMirrorBookDetailsCard from '../../../analytics-details-v2/view/work-mirror/WorkMirrorBookDetailsCard'
import {
  selectShareAnalyticDetailsStateAnalyticDetails,
  selectShareAnalyticDetailsStateAnalyticDetailsOutput,
} from '../../../../store/getters/share-analytic-details.getters'
import { BookInterface } from '../../../../core/interface/book.interface'
import GlBookmetrieBanner from '../../../share/GlBookmetrieBanner'
import { ModifiedDateStyle } from '../../../analytics-details-v2/view/readership-profile/ReadershipProfileStyles'

const ShareAnalyticDetailsReadershipProfilView = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const [showBookCard, setShowBookCard] = useState(true)

  const analyticsDetails = useAppSelector(
    selectShareAnalyticDetailsStateAnalyticDetails,
  )
  const analyticsColorConfig =
    SetColorConfig.find(
      (colorConfig) =>
        colorConfig.id === analyticsDetails.inputs[0].visual_theme,
    ) || SetColorConfig[0]

  const masonry = useRef<Masonry>()

  const isLoadingReadershipProfileInput = useAppSelector(
    selectAnalyticDetailsReadershipProfileStateIsLoadingReadershipProfileInput,
  )

  const outputs = useAppSelector(
    selectShareAnalyticDetailsStateAnalyticDetailsOutput,
  )

  const studyObjectType = useAppSelector(
    selectAnalyticDetailsReadershipProfileStateStudyObjectType,
  )
  const studyObjectValue = useAppSelector(
    selectAnalyticDetailsReadershipProfileStateStudyObjectValue,
  )
  const studyObjectBooks = useAppSelector(
    selectAnalyticDetailsReadershipProfileStateStudyObjectBooks,
  )

  const compareGroupType = useAppSelector(
    selectAnalyticDetailsReadershipProfileStateCompareGroupType,
  )
  const compareGroupValue = useAppSelector(
    selectAnalyticDetailsReadershipProfileStateCompareGroupValue,
  )
  const compareGroupBooks = useAppSelector(
    selectAnalyticDetailsReadershipProfileStateCompareGroupBooks,
  )

  const {
    isLoadingBookDetailsGetBookReco,
    bookDetailsBookReco,
    isLoadingGetBookDetails,
    isLoadingGetEditions,
    isLoadingGetB2BBookDetails,
    bookDetailsSelected,
    bookDetailsSelectedEditions,
    bookDetailsSelectedB2BInfo,
  } = useAppSelector(selectAnalyticDetailsStateWorkMirrorBookDetailsCardValues)

  useEffect(() => {
    const elem = document.querySelector('.js-masonry')
    if (elem) {
      masonry.current = new Masonry(elem, {
        itemSelector: '.grid-item',
        columnWidth: '.grid-sizer',
        percentPosition: true,
        resize: false,
        transitionDuration: 0,
      })
    }
  }, [])

  const refreshLayout = () => {
    masonry.current?.reloadItems && masonry.current.reloadItems()
    masonry.current?.layout && masonry.current.layout()
  }

  useEffect(() => {
    if (masonry.current && outputs.length > 0) {
      refreshLayout()
    }
  }, [outputs])

  useEffect(() => {
    if (analyticsDetails) {
      void dispatch(
        analyticsDetailsReadershipProfileStateGetPreviewValue({
          analyticsDetails,
        }),
      )
    }
  }, [analyticsDetails])

  const scrollToTreemap = useCallback((id: string) => {
    const element = document.getElementById(id)
    if (element) {
      element.scrollIntoView({
        block: 'start',
      })
    }
  }, [])

  const handleCloseBookDetails = useCallback(() => {
    void dispatch(analyticDetailsV2StateGetBookDetailsDataById({ eanId: null }))
  }, [bookDetailsSelected])

  const handleOpenBookDetails = useCallback(
    (eanId: number | null, showBookDetail: boolean) => {
      setShowBookCard(showBookDetail)
      void dispatch(analyticDetailsV2StateGetBookDetailsDataById({ eanId }))
    },
    [
      bookDetailsSelected,
      bookDetailsSelectedEditions,
      bookDetailsSelectedB2BInfo,
    ],
  )

  return (
    <>
      <div className="flex flex-col gap-4 flex-auto px-8 py-4">
        <div className="relative rounded-md bg-white flex flex-row justify-center items-center p-2  ">
          {(isLoadingReadershipProfileInput && (
            <div className="flex flex-row items-center justify-center m-[-10px]">
              <GlLoader width={32} height={32} />
            </div>
          )) || (
            <div className="flex flex-row gap-2 items-center justify-center ">
              <span>{t('iStudyTheReadersOf')}</span>
              {(studyObjectType && studyObjectValue && (
                <RecapInputTag<StudyObjectType, StudyObjectValueType>
                  type={studyObjectType}
                  value={studyObjectValue}
                  color={analyticsColorConfig.color1}
                  booksSelected={studyObjectBooks}
                />
              )) ||
                null}
              <span>{t('inComparisonWithThoseOf')}</span>
              {(compareGroupType && compareGroupValue && (
                <RecapInputTag<CompareGroupType, CompareGroupValueType>
                  type={compareGroupType}
                  value={compareGroupValue}
                  color={analyticsColorConfig.color2}
                  booksSelected={compareGroupBooks}
                />
              )) ||
                null}
            </div>
          )}
        </div>
        <div className="flex gap-4">
          <PerfectScrollbar
            options={{ suppressScrollX: true }}
            style={{
              height: 'calc(100vh - 142px)',
            }}
            className="flex flex-col rounded-xl bg-white py-4 px-8 gap-[32px] w-full"
          >
            <GlBookmetrieBanner />
            <ModifiedDateStyle>
              {`${t('ReportReaderShipProfileDate')} ${dayjs(
                analyticsDetails.modified,
              ).format('DD MMMM	YYYY')}`}
            </ModifiedDateStyle>
            <div>
              <div className="grid js-masonry">
                <div className="grid-sizer" />
                <div className="gutter-sizer" />
                {outputs.map((output: AnalyticOutputInterface) => (
                  <OutputTypeSwitch
                    key={`output-${output.id}`}
                    output={output}
                    scrollToTreemap={scrollToTreemap}
                    eanSelected={
                      bookDetailsSelected && bookDetailsSelected.id_ean
                    }
                    handleOpenBookDetails={handleOpenBookDetails}
                    analyticsColorConfig={analyticsColorConfig}
                    refreshLayout={refreshLayout}
                  />
                ))}
              </div>
            </div>
          </PerfectScrollbar>
        </div>
      </div>
      {bookDetailsSelected && (
        <div className="absolute top-[48px] ">
          <GlSideBar
            open
            position="right"
            hasOverlay
            width={506}
            scrollable={false}
            height="h-full"
          >
            <WorkMirrorBookDetailsCard
              isLoadingBookDetailsGetBookReco={isLoadingBookDetailsGetBookReco}
              bookDetailsBookReco={bookDetailsBookReco}
              isLoadingGetBookDetails={isLoadingGetBookDetails}
              isLoadingGetEditions={isLoadingGetEditions}
              isLoadingGetB2BBookDetails={isLoadingGetB2BBookDetails}
              openListCard={false}
              bookDetails={bookDetailsSelected}
              bookDetailsSelectedEditions={
                showBookCard
                  ? bookDetailsSelectedEditions.filter(
                      (edition: BookInterface) =>
                        edition.id_ean === bookDetailsSelected.id_ean,
                    )
                  : bookDetailsSelectedEditions
              }
              bookDetailsSelectedB2BInfo={bookDetailsSelectedB2BInfo}
              handleCloseBookDetails={handleCloseBookDetails}
            />
          </GlSideBar>
        </div>
      )}
    </>
  )
}

export default ShareAnalyticDetailsReadershipProfilView
