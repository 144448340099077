import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { intersection, uniqBy } from 'lodash'
import styled from '@emotion/styled'
import { ReactComponent as BottomArrowIcon } from '../../../../../../assets/icons/app/bottom-chevron.icon.svg'
import { ReactComponent as GroupIcon } from '../../../../../../assets/icons/app/group.icon.svg'
import { ReactComponent as SearchIcon } from '../../../../../../assets/icons/app/search.icon.svg'
import { ReactComponent as CrossIcon } from '../../../../../../assets/icons/app/cross.icon.svg'
import useLoadEditionsByAuthorHook from './useLoadEditionsByAuthorHook'
import GlLoader from '../../../../../share/GlLoader'
import EditionsTableSelectable from '../../editions-table/EditionsTableSelectable'
import { BookInterface } from '../../../../../../core/interface/book.interface'
import { AuthorInterface } from '../../../../../../core/interface/author.interface'
import {
  AuthorBookStyle,
  InputStyle,
  TitleBookStyle,
} from '../../ReadershipProfileStyles'

const EditionsButtonStyle = styled.button`
  font-family: Fira Sans, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.24px;
  white-space: nowrap;
`

const GroupByButtonStyle = styled.button`
  width: 126px;
  padding: 4px 8px;
  overflow: hidden;
  color: #252525;
  text-overflow: ellipsis;
  font-family: Fira Sans, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.24px;
`

const HasCursorStyle = styled.div`
  height: 8px;
  width: 8px;
  background: white;
  border-radius: 6px;
  border: 2px solid #252525;
  position: absolute;
  top: -4px;
  right: -4px;
`
export enum AuthorInputActionTypeEnum {
  GROUP,
  SEARCH,
}

export type AuthorInputActionType =
  | AuthorInputActionTypeEnum.GROUP
  | AuthorInputActionTypeEnum.SEARCH
  | null

export enum AuthorInputGroupByEnum {
  SERIES = 'series_id',
  TITLE = 'glid',
}

export type AuthorInputGroupByType =
  | AuthorInputGroupByEnum.SERIES
  | AuthorInputGroupByEnum.TITLE
  | null

const EditionsByAuthorCard = ({
  disable,
  color,
  author,
  booksSelected,
  handleUpdateBooksSelected,
}: {
  disable: boolean
  color: string
  author: AuthorInterface
  booksSelected: BookInterface[]
  handleUpdateBooksSelected: (books: BookInterface[]) => void
}) => {
  const { t } = useTranslation()
  const [action, setAction] = useState<AuthorInputActionType>(
    AuthorInputActionTypeEnum.GROUP,
  )
  const [groupByAction, setGroupByAction] = useState<AuthorInputGroupByType>(
    AuthorInputGroupByEnum.SERIES,
  )
  const [queryFilterAction, setQueryFilterAction] = useState('')

  const [openTableMenuEditions, setOpenTableMenuEditions] =
    useState<boolean>(false)

  const {
    state: { isLoading, editions },
  } = useLoadEditionsByAuthorHook({
    authorId: author.id,
    callbackAfterLoad: (books: BookInterface[]) => {
      const booksAlreadySelected = books.filter((book) =>
        booksSelected.some(
          (selectedBook) => selectedBook.id_ean === book.id_ean,
        ),
      )
      const booksUniqBy = uniqBy([...booksSelected, ...books], 'id_ean')
      if (
        booksSelected.length < booksUniqBy.length &&
        booksAlreadySelected.length === 0
      ) {
        handleUpdateBooksSelected(booksUniqBy)
      }
    },
  })

  const onClickToggleTableMenuEditions = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault()
    setOpenTableMenuEditions(!openTableMenuEditions)
  }

  const getEditionsNumberSelected = (): number => {
    return intersection(
      editions.map((book: BookInterface) => book.id_ean),
      booksSelected.map((book: BookInterface) => book.id_ean),
    ).length
  }

  const onClickGroupButton = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    const newAction =
      action === AuthorInputActionTypeEnum.GROUP
        ? null
        : AuthorInputActionTypeEnum.GROUP
    setAction(newAction)
    setGroupByAction(
      newAction === AuthorInputActionTypeEnum.GROUP
        ? AuthorInputGroupByEnum.SERIES
        : null,
    )
  }

  const onClickSearchButton = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    const newAction =
      action === AuthorInputActionTypeEnum.SEARCH
        ? null
        : AuthorInputActionTypeEnum.SEARCH
    setAction(newAction)
    if (groupByAction) {
      setGroupByAction(null)
    }
  }

  const onClickGroupByButtonSeries = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault()
    setGroupByAction(
      groupByAction === AuthorInputGroupByEnum.SERIES
        ? null
        : AuthorInputGroupByEnum.SERIES,
    )
  }

  const onClickGroupByButtonTitle = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault()
    setGroupByAction(
      groupByAction === AuthorInputGroupByEnum.TITLE
        ? null
        : AuthorInputGroupByEnum.TITLE,
    )
  }

  const getInitial = (): string | null => {
    const words = author.name.replace(/[!@#$%^&*_.()]/g, '').split(' ')
    if (words.length === 1) {
      const oneWords = words[0].split('-')
      if (oneWords.length > 1) {
        return oneWords.map((value: string) => value.charAt(0)).join('')
      }
      return words[0].slice(0, 2)
    }
    return words
      .slice(0, 2)
      .map((value: string) => value.charAt(0))
      .join('')
  }

  const onChangeQueryFilterValue = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setQueryFilterAction(event.target.value)
  }

  const onClickResetFilterAction = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault()
    setQueryFilterAction('')
  }
  if (isLoading) {
    return (
      <div className="flex flex-col bg-[#252525] rounded items-center justify-center h-[48px]">
        <GlLoader height={42} width={42} />
      </div>
    )
  }

  return (
    <>
      <div className="flex flex-row p-1 bg-[#252525] rounded gap-2 h-[48px]">
        <div
          className="rounded bg-[#f7f7f7] flex flex-none items-center justify-center"
          style={{
            width: 40,
            height: 40,
          }}
        >
          {getInitial()}
        </div>
        <div className="flex-auto flex flex-col gap-1">
          <TitleBookStyle color="white">
            <span>{author.name}</span>
          </TitleBookStyle>
          <AuthorBookStyle
            color="#bebebe"
            className="flex flex-row items-center justify-start gap-1"
          >
            <span>{t('editions', { count: editions.length || 0 })}</span>
          </AuthorBookStyle>
        </div>
        <div className="flex items-center justify-center">
          <EditionsButtonStyle
            type="button"
            className="p-2 flex items-center justify-center gap-2 rounded"
            style={{
              color,
              backgroundColor: `${color}1a`,
            }}
            onClick={onClickToggleTableMenuEditions}
          >
            <span>
              {t('editionsSelected', { count: getEditionsNumberSelected() })}{' '}
            </span>
            <BottomArrowIcon
              style={{
                width: 12,
                height: 12,
                transform: openTableMenuEditions
                  ? 'rotate(180deg)'
                  : 'rotate(0deg)',
              }}
            />
          </EditionsButtonStyle>
        </div>
        <div className="flex flex-row gap-2 items-center justify-center">
          <button
            type="button"
            className="rounded relative"
            style={{
              backgroundColor:
                action === AuthorInputActionTypeEnum.GROUP
                  ? 'white'
                  : '#ffffff1a',
              color:
                action === AuthorInputActionTypeEnum.GROUP
                  ? '#252525'
                  : 'white',
              padding: 6,
            }}
            onClick={onClickGroupButton}
          >
            <GroupIcon
              style={{
                width: 20,
                height: 20,
              }}
            />
            {(groupByAction && <HasCursorStyle />) || null}
          </button>
          <button
            type="button"
            className="rounded relative"
            style={{
              backgroundColor:
                action === AuthorInputActionTypeEnum.SEARCH
                  ? 'white'
                  : '#ffffff1a',
              color:
                action === AuthorInputActionTypeEnum.SEARCH
                  ? '#252525'
                  : 'white',
              padding: 6,
            }}
            onClick={onClickSearchButton}
          >
            <SearchIcon
              style={{
                width: 20,
                height: 20,
              }}
            />
            {(queryFilterAction && <HasCursorStyle />) || null}
          </button>
        </div>
      </div>
      {(openTableMenuEditions && action === AuthorInputActionTypeEnum.GROUP && (
        <div
          className="flex-none flex flex-row gap-2 items-center justify-center"
          style={{
            margin: '8px 0 16px 0',
          }}
        >
          <div className="flex-none flex flex-row gap-2 bg-[#2525251a] rounded p-0.5">
            <GroupByButtonStyle
              type="button"
              className="rounded"
              onClick={onClickGroupByButtonSeries}
              style={{
                backgroundColor:
                  groupByAction === AuthorInputGroupByEnum.SERIES
                    ? 'white'
                    : 'transparent',
              }}
            >
              <span>Série</span>
            </GroupByButtonStyle>
            <GroupByButtonStyle
              type="button"
              className="rounded"
              onClick={onClickGroupByButtonTitle}
              style={{
                backgroundColor:
                  groupByAction === AuthorInputGroupByEnum.TITLE
                    ? 'white'
                    : 'transparent',
              }}
            >
              <span>Titre</span>
            </GroupByButtonStyle>
          </div>
        </div>
      )) ||
        null}
      {(openTableMenuEditions &&
        action === AuthorInputActionTypeEnum.SEARCH && (
          <div
            className="flex flex-col relative"
            style={{
              margin: '8px 0 16px 0',
            }}
          >
            <InputStyle
              type="text"
              placeholder="Rechercher"
              className="w-full"
              value={queryFilterAction}
              onChange={onChangeQueryFilterValue}
              style={{
                margin: '-10px 0 -10px',
              }}
            />
            {queryFilterAction && (
              <button
                type="button"
                onClick={onClickResetFilterAction}
                className="absolute p-2 top-0 right-2"
              >
                <CrossIcon
                  style={{
                    width: 16,
                    height: 16,
                  }}
                />
              </button>
            )}
          </div>
        )) ||
        null}
      {(openTableMenuEditions && (
        <EditionsTableSelectable
          disable={disable}
          booksSelected={booksSelected}
          handleUpdateBooksSelected={handleUpdateBooksSelected}
          books={editions}
          color={color}
          groupByAction={groupByAction}
          filterByAction={queryFilterAction}
        />
      )) ||
        null}
    </>
  )
}

export default EditionsByAuthorCard
