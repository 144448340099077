import React, { memo, useCallback } from 'react'
import {
  Bar,
  BarChart,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { useTranslation } from 'react-i18next'
import { DatasetInterface } from '../../../core/interface/analytics/dataset.interface'
import { TootipContainerStyle } from '../../analytics-details-v2/view/readership-profile/ReadershipProfileStyles'
import CustomContentValuesTooltip from '../../analytics-details-v2/view/readership-profile/outputs/chart/chartComponents/tooltip/tooltipContent/CustomContentValuesTooltip'

interface Props {
  dataset: DatasetInterface[]
}

const TooltipContent = (data: any) => {
  const { t } = useTranslation()

  const { active, payload, label } = data

  if (active && payload && payload[0]) {
    const [min, max] = label.replace(/[^\d.;]/g, '').split(';')

    return (
      <TootipContainerStyle>
        <span>{t('audienceView.scoreRange', { min, max })}</span>
        <CustomContentValuesTooltip
          values={[
            {
              value: payload[0].value * 100,
              color: '#86DAAD',
              key: payload[0].dataKey,
            },
          ]}
          maximumFractionDigits={0}
        />
      </TootipContainerStyle>
    )
  }
  return null
}

const AudienceVerticalRechart = ({ dataset }: Props) => {
  const { t } = useTranslation()

  const renderLegend = useCallback(() => {
    return (
      <span style={{ color: '#86DAAD' }}>
        {t('audienceView.usersPercentage')}
      </span>
    )
  }, [])

  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart data={dataset}>
        <XAxis
          dataKey="score"
          tickFormatter={(value: string) =>
            value.replaceAll(' ', '').replace(';', '-')
          }
        />
        <YAxis
          dataKey="users_number"
          tickFormatter={(value: number) => `${(value * 100).toString()}%`}
        />
        <Tooltip content={TooltipContent} />
        <Legend verticalAlign="top" formatter={renderLegend} />
        <Bar dataKey="users_number" fill="#86DAAD" />
      </BarChart>
    </ResponsiveContainer>
  )
}

export default memo(AudienceVerticalRechart)
