import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { intersection, uniqBy } from 'lodash'
import styled from '@emotion/styled'
import { BookSearchInterface } from '../../../../../../core/interface/book-search.interface'
import { ReactComponent as ResetIcon } from '../../../../../../assets/icons/app/reset-icon.svg'
import { ReactComponent as BottomArrowIcon } from '../../../../../../assets/icons/app/bottom-chevron.icon.svg'
import useLoadEditionsHook from './useLoadEditionsByTitleHook'
import GlLoader from '../../../../../share/GlLoader'
import EditionsTableSelectable from '../../editions-table/EditionsTableSelectable'
import { BookInterface } from '../../../../../../core/interface/book.interface'
import { ReactComponent as BookIcon } from '../../../../../../assets/icons/app/book.icon.svg'
import { AuthorBookStyle, TitleBookStyle } from '../../ReadershipProfileStyles'
import { WorkMirrorBookInterface } from '../../../../../../core/interface/report/work-mirror-book.interface'

const EditionsButtonStyle = styled.button`
  font-family: Fira Sans, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.24px;
  white-space: nowrap;
`

const EditionsByTitleCard = ({
  disable,
  color,
  book,
  handleOnClikRemoveItem,
  booksSelected,
  handleUpdateBooksSelected,
  handleOpenBookDetails,
  eanSelected,
}: {
  disable: boolean
  color: string
  book: BookSearchInterface
  handleOnClikRemoveItem: (booksToRemove: BookInterface[]) => void
  booksSelected: BookInterface[]
  handleUpdateBooksSelected: (books: BookInterface[]) => void
  handleOpenBookDetails: (eanId: number | null) => void
  eanSelected: number | null
}) => {
  const { t } = useTranslation()
  const [openTableMenuEditions, setOpenTableMenuEditions] =
    useState<boolean>(false)

  const [booksToRemove, setEanIdsToRemove] = useState<BookInterface[]>([])
  const {
    state: { isLoading, editions },
  } = useLoadEditionsHook({
    glId: book.glid,
    callbackAfterLoad: (books: BookInterface[]) => {
      const booksAlreadySelected = books.filter((book) =>
        booksSelected.some(
          (selectedBook) => selectedBook.id_ean === book.id_ean,
        ),
      )
      const booksUniqBy = uniqBy([...booksSelected, ...books], 'id_ean')
      if (
        booksSelected.length < booksUniqBy.length &&
        booksAlreadySelected.length === 0
      ) {
        setEanIdsToRemove([...books])
        handleUpdateBooksSelected(booksUniqBy)
      }
    },
  })

  if (isLoading) {
    return (
      <div className="flex flex-col bg-[#252525] rounded items-center justify-center h-[48px]">
        <GlLoader height={42} width={42} />
      </div>
    )
  }

  const onClickRemove = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    event.preventDefault()
    handleOnClikRemoveItem(booksToRemove || [])
    eanSelected === book.id_ean && handleOpenBookDetails(null)
  }

  const onClickToggleTableMenuEditions = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.stopPropagation()
    event.preventDefault()
    setOpenTableMenuEditions(!openTableMenuEditions)
  }

  const getEditionsNumberSelected = (): number => {
    return intersection(
      editions.map((book: BookInterface) => book.id_ean),
      booksSelected.map((book: BookInterface) => book.id_ean),
    ).length
  }

  const onClickTitleCard = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault()
    handleOpenBookDetails(eanSelected === book.id_ean ? null : book.id_ean)
  }

  return (
    <>
      <div
        className="flex flex-row p-1 bg-[#252525] rounded gap-2 h-[48px] cursor-pointer"
        onClick={onClickTitleCard}
      >
        <div
          className="flex-none flex items-center justify-center rounded bg-[#f7f7f7]"
          style={{
            width: 40,
            height: 40,
          }}
        >
          {(book.imgs && (
            <img
              className="rounded-sm"
              src={`${book.imgs}`}
              alt=""
              style={{
                minWidth: 40,
                maxWidth: 40,
                minHeight: 40,
                maxHeight: 40,
                objectFit: 'cover',
              }}
            />
          )) || (
            <BookIcon
              style={{
                width: 24,
                height: 24,
              }}
            />
          )}
        </div>
        <div className="flex-auto flex flex-col gap-1">
          <TitleBookStyle color="white">{book.title}</TitleBookStyle>
          <AuthorBookStyle
            color="#bebebe"
            className="flex flex-row items-center justify-start gap-1"
          >
            {t('authorAndEditionsNumbers', {
              authorName: book.authors[0],
              count: editions.length || 0,
            })}
          </AuthorBookStyle>
        </div>
        <div className="flex-none flex items-center justify-center">
          <EditionsButtonStyle
            type="button"
            className="p-2 flex items-center justify-center gap-2"
            style={{
              color,
              backgroundColor: `${color}1a`,
            }}
            onClick={onClickToggleTableMenuEditions}
          >
            <span>
              {t('editionsSelected', { count: getEditionsNumberSelected() })}
            </span>
            <BottomArrowIcon
              style={{
                width: 12,
                height: 12,
                transform: openTableMenuEditions
                  ? 'rotate(180deg)'
                  : 'rotate(0deg)',
              }}
            />
          </EditionsButtonStyle>
        </div>
        <div className="flex items-center justify-center">
          <button
            type="button"
            className="text-[#bebebe]"
            style={{
              padding: 6,
            }}
            disabled={disable}
            onClick={onClickRemove}
          >
            <ResetIcon
              style={{
                width: 20,
                height: 20,
              }}
            />
          </button>
        </div>
      </div>

      {(openTableMenuEditions && (
        <EditionsTableSelectable
          disable={disable}
          booksSelected={booksSelected}
          handleUpdateBooksSelected={handleUpdateBooksSelected}
          books={editions}
          color={color}
        />
      )) ||
        null}
    </>
  )
}

export default EditionsByTitleCard
