import dayjs from 'dayjs'
import styled from '@emotion/styled'
import React, { useMemo } from 'react'
import { ReportLightResponseInterface } from '../../../core/interface/report/report-light-response.interface'
import GlCellBodyV3 from '../../share/table/GlCellBodyV3'
import { getFullNameUtil } from '../../../core/utils/get-full-name.util'
import { BookInterface } from '../../../core/interface/book.interface'
import AnalyticsDashboardMoreActionMenu from '../more-actions/AnalyticsDashboardMoreActionMenu'
import AnalyticIcon from '../AnalyticIcon'
import getAnalyticColor from '../../../core/utils/get-analytic-color.util'
import getAnalyticInputBooksUtil from '../../../core/utils/get-analytic-input-books.util'
import { AnalyticsTypeEnum } from '../../../core/enums/analytics-type.enum'

const LineStyle = styled.div`
  cursor: pointer;
  :hover {
    background: #f7f7f799;
  }

  :active {
    background: #f7f7f7;
  }
`
const TableAnalyticsBody = ({
  isFirst = false,
  analytic,
  handleReloadAction,
  handleClickAnalytics,
  companyUserId,
}: {
  isFirst?: boolean
  analytic: ReportLightResponseInterface
  handleReloadAction: () => void
  handleClickAnalytics: (id: string, type: AnalyticsTypeEnum) => void
  companyUserId: string
}) => {
  const inputBooks = useMemo(() => {
    return getAnalyticInputBooksUtil(analytic)
  }, [analytic])

  const onClickLine = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault()
    handleClickAnalytics(analytic.id, analytic.type)
  }

  return (
    <LineStyle className="flex flex-row" onClick={onClickLine}>
      <GlCellBodyV3 className="min-w-[112px] max-w-[112px]">
        <div
          className="relative flex flex-row w-full rounded"
          style={{
            backgroundColor: getAnalyticColor(analytic.type, analytic.status),
            height: 48,
            width: 96,
          }}
        >
          <div
            className="absolute flex items-center justify-center w-full h-full"
            style={{
              zIndex: 1,
            }}
          >
            <AnalyticIcon type={analytic.type} status={analytic.status} />
          </div>
          {inputBooks.map((book: BookInterface, index: number) => (
            <img
              key={`book-miniature-cover-${analytic.id}-${book.id_ean}-${
                index + 1
              }`}
              src={book.imgs}
              alt="Cover book"
              style={{
                width: 96 / inputBooks.length,
                height: 48,
                objectFit: 'cover',
                zIndex: 2,
              }}
            />
          ))}
        </div>
      </GlCellBodyV3>
      <GlCellBodyV3
        className="min-w-[260px]  flex-1"
        labelValue={analytic.name}
        main
      />
      <GlCellBodyV3
        className="min-w-[120px] max-w-[260px]"
        labelValue={dayjs(analytic.modified).format('DD/MM/YYYY')}
      />
      <GlCellBodyV3
        className="min-w-[120px] max-w-[260px]"
        labelValue={dayjs(analytic.created).format('DD/MM/YYYY')}
      />
      <GlCellBodyV3
        className="min-w-[120px] max-w-[260px]"
        labelValue={getFullNameUtil(analytic?.owner as any)}
      />
      <GlCellBodyV3 className="min-w-[48px] max-w-[48px]">
        <div className="relative">
          <AnalyticsDashboardMoreActionMenu
            menuPositionTop={isFirst}
            className="mr-4 more-action-btn"
            analytic={analytic}
            isOwner={analytic.owner?.id === companyUserId}
            handleReloadAction={handleReloadAction}
          />
        </div>
      </GlCellBodyV3>
    </LineStyle>
  )
}
export default TableAnalyticsBody
