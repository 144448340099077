import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import GlDialogTitle from '../../share/dialog/GlDialogTitle'
import GlDialogBody from '../../share/dialog/GlDialogBody'
import {
  getReportByIdService,
  postReportService,
} from '../../../core/api/services/bookmetrie/reports.service'
import { setShowSnackBar } from '../../../store/reducers/web-app.reducer'
import { useAppDispatch } from '../../../store/hooks'
import GlSpinner from '../../share/GlSpinner'
import { ReportDuplicateFromDashboardModel } from '../../../core/model/report-duplicate-from-dashboard.model'
import { buildRelativePath, PathConfig } from '../../../core/config/PathConfig'

const Button = styled.button`
  color: ${({ primary = false }: { primary?: boolean }) =>
    (primary && 'white') || '#3B3B3B'};
  background-color: ${({ primary = false }: { primary?: boolean }) =>
    (primary && '#252525') || '#E9E9E9'};
  opacity: ${({
    primary = false,
    isLoading,
  }: {
    primary?: boolean
    isLoading?: boolean
  }) => (isLoading && 0.6) || (primary && 1) || 0.7};
  border-radius: 4px;
  text-align: center;
  /* Body/B1 - Fira - Medium */
  font-family: Fira Sans, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  text-transform: uppercase;
  height: 36px;

  :disabled {
    background-color: ${({ primary = false }: { primary?: boolean }) =>
      (primary && '#252525b3') || '#E9E9E9cc'};
  }

  :hover {
    background-color: ${({ primary = false }: { primary?: boolean }) =>
      (primary && '#252525cc') || '#E9E9E9cc'};
  }
  :active {
    background-color: ${({ primary = false }: { primary?: boolean }) =>
      (primary && '#252525e6') || '#E9E9E9e6'};
  }
`

const AnalyticsDashboardDuplicateDialog = ({
  analyticsId,
  analyticsName,
  handleCancelAction,
}: {
  analyticsId: string
  analyticsName: string
  handleCancelAction: () => void
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const [isLoadingDuplicateAction, setIsLoadingDuplicateAction] =
    useState<boolean>(false)

  const onClickCancelButton = (event: React.MouseEvent) => {
    event.preventDefault()
    handleCancelAction()
  }

  const onClickDuplicateButton = (event: React.MouseEvent) => {
    event.preventDefault()
    setIsLoadingDuplicateAction(true)
    getReportByIdService(analyticsId)
      .then(async (response) => {
        const { data } = response
        const reportToDuplicate = new ReportDuplicateFromDashboardModel(data)
        try {
          const { data } = await postReportService(reportToDuplicate)
          if (data) {
            handleCancelAction()
            dispatch(
              setShowSnackBar(
                t('snackbarMessage.duplicateReportConfirm', {
                  reportName: analyticsName,
                }),
              ),
            )
            navigate(
              buildRelativePath([
                PathConfig.BOOKMETRIE,
                PathConfig.ANALYTICS,
                data.id,
              ]),
            )
          }
        } catch (error) {
          dispatch(setShowSnackBar(t('snackbarMessage.errorMessage')))
        } finally {
          setIsLoadingDuplicateAction(false)
        }
      })
      .catch(() => {
        setIsLoadingDuplicateAction(false)
      })
  }

  return (
    <div className="flex flex-col gap-4">
      <GlDialogTitle label={analyticsName} />
      <GlDialogBody>
        <span>{t('webApp.reports.confirmDuplicateAnalytics')}</span>
      </GlDialogBody>
      <div className="flex flex-row gap-2">
        <Button className="flex-1" onClick={onClickCancelButton}>
          {t('commons.buttons.cancel')}
        </Button>
        <Button
          className="relative flex-1"
          primary
          onClick={onClickDuplicateButton}
          disabled={isLoadingDuplicateAction}
        >
          {(isLoadingDuplicateAction && (
            <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
              <GlSpinner />
            </div>
          )) ||
            null}
          {t('commons.buttons.duplicate')}
        </Button>
      </div>
    </div>
  )
}

export default AnalyticsDashboardDuplicateDialog
