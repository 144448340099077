import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import React, { useState } from 'react'
import GlDialogTitle from '../../share/dialog/GlDialogTitle'
import GlDialogBody from '../../share/dialog/GlDialogBody'
import { patchReportService } from '../../../core/api/services/bookmetrie/reports.service'
import { setShowSnackBar } from '../../../store/reducers/web-app.reducer'
import { useAppDispatch } from '../../../store/hooks'
import GlSpinner from '../../share/GlSpinner'
import GlMatInputOutline from '../../share/inputs/GlMatInputOutline'

const Button = styled.button`
  color: ${({ primary = false }: { primary?: boolean }) =>
    (primary && 'white') || '#3B3B3B'};
  background-color: ${({ primary = false }: { primary?: boolean }) =>
    (primary && '#252525') || '#E9E9E9'};
  opacity: ${({
    primary = false,
    isLoading,
  }: {
    primary?: boolean
    isLoading?: boolean
  }) => (isLoading && 0.6) || (primary && 1) || 0.7};
  border-radius: 4px;
  text-align: center;
  /* Body/B1 - Fira - Medium */
  font-family: Fira Sans, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  text-transform: uppercase;
  height: 36px;

  :disabled {
    background-color: ${({ primary = false }: { primary?: boolean }) =>
      (primary && '#252525b3') || '#E9E9E9cc'};
  }

  :hover {
    background-color: ${({ primary = false }: { primary?: boolean }) =>
      (primary && '#252525cc') || '#E9E9E9cc'};
  }
  :active {
    background-color: ${({ primary = false }: { primary?: boolean }) =>
      (primary && '#252525e6') || '#E9E9E9e6'};
  }
`

const AnalyticsDashboardRenameDialog = ({
  analyticsId,
  analyticsName,
  handleCancelAction,
  handleReloadAction,
}: {
  analyticsId: string
  analyticsName: string
  handleCancelAction: () => void
  handleReloadAction: () => void
}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const [analyticNameInternal, setAnalyticNameInternal] =
    useState<string>(analyticsName)

  const [isLoadingDeleteAction, setIsLoadingDeleteAction] =
    useState<boolean>(false)

  const onClickCancelButton = (event: React.MouseEvent) => {
    event.preventDefault()
    handleCancelAction()
  }

  const onClickRenameButton = (event: React.MouseEvent) => {
    event.preventDefault()
    setIsLoadingDeleteAction(true)

    patchReportService(analyticsId, { name: analyticNameInternal })
      .then(() => {
        dispatch(
          setShowSnackBar(
            t('snackbarMessage.renameReportConfirm', {
              reportName: analyticNameInternal,
            }),
          ),
        )
        handleReloadAction()
        handleCancelAction()
      })
      .catch(() => {
        dispatch(setShowSnackBar(t('snackbarMessage.errorMessage')))
      })
      .finally(() => {
        setIsLoadingDeleteAction(false)
      })
  }

  return (
    <div className="flex flex-col gap-4">
      <GlDialogTitle label={analyticsName} />
      <GlDialogBody>
        <div>{t('webApp.reports.confirmRenameAnalytics')}</div>
        <GlMatInputOutline
          type="text"
          onChangeValue={(value: string) => {
            setAnalyticNameInternal(value)
          }}
          label=""
          value={analyticNameInternal}
          placeholder={t('analyticNamePlaceHolder')}
          hasClearButton
          hasError={!analyticNameInternal}
          hasErrorMessage={t('analyticNameRequired')}
        />
      </GlDialogBody>
      <div className="flex flex-row gap-2">
        <Button className="flex-1" onClick={onClickCancelButton}>
          {t('commons.buttons.cancel')}
        </Button>
        <Button
          className="relative flex-1"
          primary
          onClick={onClickRenameButton}
          disabled={isLoadingDeleteAction}
        >
          {(isLoadingDeleteAction && (
            <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
              <GlSpinner />
            </div>
          )) ||
            null}
          {t('commons.buttons.rename')}
        </Button>
      </div>
    </div>
  )
}

export default AnalyticsDashboardRenameDialog
