import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
  CompareGroupType,
  StudyObjectGroupCompareEnum,
} from '../../analytics-details-v2/view/readership-profile/configs/StudiesObjectsCompareGroupTypesConfig'
import { CompareGroupValueType } from '../../../store/interfaces/analytics-details/new-readership-profile-state.interface'
import { BookInterface } from '../../../core/interface/book.interface'
import GlVDivider from '../../share/GlVDivider'
import NewAudienceTarget from './NewAudienceTarget'
import AudienceCatalogInterface from '../../../core/interface/audience/audience-catalog.interface'
import NewAudienceCatalog from './NewAudienceCatalog'
import GlHDivider from '../../share/GlHDivider'
import NewAudienceVolume from './NewAudienceVolume'
import AudienceRecap from '../AudienceRecap'
import NewPrivateAudienceInterface from '../../../core/interface/report/new-private-audience.interface'
import getAnalyticsIdsValueUtils from '../../../core/utils/getAnalyticsIdsValue.utils'
import PostPrivateAudienceService from '../../../core/api/services/bookmetrie/private-reports/audience/post-private-audience.service'
import { setShowSnackBar } from '../../../store/reducers/web-app.reducer'
import { useAppDispatch } from '../../../store/hooks'
import { buildRelativePath, PathConfig } from '../../../core/config/PathConfig'

const FULL_GLEEPH_CATALOG = 400000
const NANO_CATALOG = 2000

interface Props {
  title: string
  isLoading: boolean
  catalogs: Array<AudienceCatalogInterface>
  eanSelected: number | null
  handleOpenBookDetails: (ean: number | null) => void
}

const NewAudience = ({
  title,
  isLoading,
  catalogs,
  eanSelected,
  handleOpenBookDetails,
}: Props) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [searchType, setSearchType] = useState<CompareGroupType>(
    StudyObjectGroupCompareEnum.TITLE,
  )
  const [values, setValues] = useState<CompareGroupValueType>(null)
  const [booksSelected, setBooksSelected] = useState<Array<BookInterface>>([])
  const [userAlreadyOwnBook, setUserAlreadyOwnBook] = useState<boolean>(false)
  const [useFullGleephCatalog, setUseFullGleephCatalog] = useState<boolean>(
    !catalogs[0],
  )
  const [catalogSelected, setCatalogSelected] =
    useState<AudienceCatalogInterface | null>(catalogs[0] || null)
  const [isNano, setIsNano] = useState<boolean>(false)
  const [volume, setVolume] = useState<number>(FULL_GLEEPH_CATALOG)
  const [isLoadingRequest, setIsLoadingRequest] = useState<boolean>(false)

  const maxVolume = useMemo(() => {
    if (useFullGleephCatalog) return FULL_GLEEPH_CATALOG
    if (isNano) return NANO_CATALOG
    return catalogSelected?.user_reco_catalog.nb_users || FULL_GLEEPH_CATALOG
  }, [useFullGleephCatalog, isNano, catalogSelected])

  useEffect(() => {
    setVolume(volume > maxVolume ? maxVolume : volume)
  }, [useFullGleephCatalog, isNano, catalogSelected])

  useEffect(() => {
    if (!values) setBooksSelected([])
  }, [values])

  const onValidate = useCallback(() => {
    setIsLoadingRequest(true)

    const body: NewPrivateAudienceInterface = {
      ids: getAnalyticsIdsValueUtils(searchType, values || []),
      eans: booksSelected.map((book) => book.id_ean.toString()),
      type: searchType,
      request_size: volume,
      is_nano: useFullGleephCatalog ? false : isNano,
      filter_user_already_own_books:
        searchType === StudyObjectGroupCompareEnum.TITLE
          ? userAlreadyOwnBook
          : false,
      title,
    }
    if (!useFullGleephCatalog && catalogSelected) {
      body.user_catalog_id = catalogSelected.id
    }
    PostPrivateAudienceService(body)
      .then((res) => {
        navigate(
          buildRelativePath([
            PathConfig.BOOKMETRIE,
            PathConfig.PRIVATE_REPORTS,
            PathConfig.AUDIENCE,
            res.data.id,
          ]),
          { replace: true },
        )
      })
      .catch(() => {
        dispatch(setShowSnackBar(t('snackbarMessage.simpleErrorMessage')))
      })
      .finally(() => {
        setIsLoadingRequest(false)
      })
  }, [
    values,
    booksSelected,
    searchType,
    userAlreadyOwnBook,
    useFullGleephCatalog,
    isNano,
    catalogSelected,
    volume,
    title,
  ])

  return (
    <div className="flex-auto flex flex-col px-8 py-4 gap-4">
      <div
        className="flex-auto flex  bg-white rounded-xl"
        style={{
          boxShadow: '0px 4px 20px 0px rgba(0, 0, 0, 0.05)',
        }}
      >
        <div className="flex-auto flex flex-row">
          <div className="flex-auto flex flex-col gap-4 p-8 basis-1/2">
            <NewAudienceTarget
              isLoading={isLoading}
              searchType={searchType}
              setSearchType={setSearchType}
              values={values}
              setValues={setValues}
              booksSelected={booksSelected}
              setBooksSelected={setBooksSelected}
              userAlreadyOwnBook={userAlreadyOwnBook}
              setUserAlreadyOwnBook={setUserAlreadyOwnBook}
              eanSelected={eanSelected}
              handleOpenBookDetails={handleOpenBookDetails}
            />
          </div>
          <GlVDivider height="100%" />
          <div className="relative flex-auto flex flex-col basis-1/2">
            <div className="absolute w-full h-full p-8">
              <PerfectScrollbar className="absolute flex flex-col h-full w-full">
                <div className="flex flex-col gap-8">
                  <NewAudienceCatalog
                    catalogs={catalogs}
                    useFullGleephCatalog={useFullGleephCatalog}
                    setUseFullGleephCatalog={setUseFullGleephCatalog}
                    catalogSelected={catalogSelected}
                    setCatalogSelected={setCatalogSelected}
                    isNano={isNano}
                    setIsNano={setIsNano}
                  />
                  <GlHDivider />
                  <NewAudienceVolume
                    volume={volume}
                    setVolume={setVolume}
                    maxVolume={maxVolume}
                  />
                </div>
              </PerfectScrollbar>
            </div>
          </div>
        </div>
      </div>
      <AudienceRecap
        searchType={searchType}
        values={values}
        booksSelected={booksSelected}
        userAlreadyOwnBook={
          searchType === StudyObjectGroupCompareEnum.TITLE
            ? userAlreadyOwnBook
            : false
        }
        useFullGleephCatalog={useFullGleephCatalog}
        catalogSelected={catalogSelected}
        isNano={useFullGleephCatalog ? false : isNano}
        volume={volume}
        onValidate={onValidate}
        isLoading={isLoadingRequest}
        menuPositionTop
      />
    </div>
  )
}

export default memo(NewAudience)
