import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { validate } from 'uuid'
import { useAppDispatch, useAppSelector } from '../../store/hooks'

import {
  selectWebAppStateCompanyUserId,
  selectWebAppStateMySelf,
  selectWebAppStateMySelfHasAnalyticsAccess,
} from '../../store/getters/web-app.getters'
import { setShowSnackBar } from '../../store/reducers/web-app.reducer'
import { buildRelativePath, PathConfig } from '../../core/config/PathConfig'
import {
  analyticDetailsV2StateNewConstellationGetBookRecoV3Action,
  analyticDetailsV2StatePostNewAnalyticsAction,
  analyticDetailsV2StatePutAnalyticDetailsByIdAction,
  analyticsDetailV2StateGetMockReadershipProfile,
  analyticsDetailV2StateGetReportByIdAction,
  analyticsDetailV2StateNewAnalyticAction,
} from '../../store/actions/analytics-details/analytic-details-v2.actions'
import AnalyticDetailsLayout from '../../components/analytics-details-v2/AnalyticDetailsLayout'
import {
  selectAnalyticDetailsStateAnalyticDetails,
  selectAnalyticDetailsStateAnalyticDetailsBooksInput,
  selectAnalyticDetailsStateAnalyticDetailsBooksOutput,
  selectAnalyticDetailsStateAnalyticDetailsType,
  selectAnalyticDetailsStateIsLoadingGetAnalyticDetails,
  selectAnalyticDetailsStateIsLoadingPostNewAnalytic,
  selectAnalyticDetailsStateIsLoadingPutAnalytic,
  selectAnalyticDetailsStateNeedSaveAnalyticDetails,
  selectAnalyticDetailsStatePostNewAnalyticError,
  selectAnalyticDetailsStatePostNewAnalyticSuccess,
  selectAnalyticDetailsStatePutAnalyticError,
  selectAnalyticDetailsStatePutAnalyticSuccess,
} from '../../store/getters/analytics-details/analytic-details-v2.getters'
import {
  analyticsDetailV2StateSetAnalyticDetailsName,
  reinitializeAnalyticDetailsV2State,
  resetPostNewAnalyticStatus,
  resetPutAnalyticStatus,
} from '../../store/reducers/analytics-details/analytic-details-v2.reducer'
import { AnalyticsTypeEnum } from '../../core/enums/analytics-type.enum'
import SwitchViewAnalytic from '../../components/analytics-details-v2/view/SwitchViewAnalytic'
import GlDialog from '../../components/share/dialog/GlDialog'
import GlSpinner from '../../components/share/GlSpinner'
import GlMatInputOutline from '../../components/share/inputs/GlMatInputOutline'
import {
  selectNewAnalyticsReadershipProfileStatePostPreviewError,
  selectNewAnalyticsReadershipProfileStatePostPreviewTypeError,
} from '../../store/getters/analytics-details/new-analytics-readership-profile.getters'
import { AnalyticPreviewTypeErrorEnum } from '../../core/enums/analytic-preview-type-error'
import BackGroundPreviewTypeError from '../../assets/images/preview-report-type-error.svg'
import AnalyticsDashboardShareDialog from '../../components/analytics-dashboard/more-actions/share-analytics/AnalyticsDashboardShareDialog'
import { patchReportService } from '../../core/api/services/bookmetrie/reports.service'
import { AnalyticsStatusEnum } from '../../core/enums/analytics-status.enum'
import { AnalyticsPermissionEnum } from '../../core/enums/analytics-permission.enum'
import GlDialogBody from '../../components/share/dialog/GlDialogBody'
import GlDialogTitle from '../../components/share/dialog/GlDialogTitle'
import GLDialogButtonStyle from '../../components/share/dialog/GLDialogButtonStyle'
import AnalyticsDialogExit from '../../components/share/dialog/AnalyticsDialogExit'

const AnalyticDetailsView = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [renameAnalyticDialog, setRenameAnalyticDialog] =
    useState<boolean>(false)
  const [hasNavigatePrevious, setHasNavigatePrevious] = useState<boolean>(false)
  const [firstEanSearch, setFirstEanSearch] = useState<string | null>('')
  const [shareDialog, setShareDialog] = useState<boolean>(false)
  const [saveBeforeExitDialog, setSaveBeforeExitDialog] =
    useState<boolean>(false)
  const [displayPreviewErrorDialog, setDisplayPreviewErrorDialog] =
    useState<boolean>(true)

  const { reportId: urlReportId } = useParams<{ reportId: string }>()
  const [reportId, setReportId] = useState<string>(urlReportId as string)
  const [searchParams] = useSearchParams()

  const dispatch = useAppDispatch()

  const analyticDetailsType = useAppSelector(
    selectAnalyticDetailsStateAnalyticDetailsType,
  )
  const isLoadingGetAnalyticDetails = useAppSelector(
    selectAnalyticDetailsStateIsLoadingGetAnalyticDetails,
  )

  const companyUserId = useAppSelector(selectWebAppStateCompanyUserId)

  const isLoadingPostNewAnalytic = useAppSelector(
    selectAnalyticDetailsStateIsLoadingPostNewAnalytic,
  )

  const isLoadingPutAnalytic = useAppSelector(
    selectAnalyticDetailsStateIsLoadingPutAnalytic,
  )
  const analyticDetails = useAppSelector(
    selectAnalyticDetailsStateAnalyticDetails,
  )

  const mySelf = useAppSelector(selectWebAppStateMySelf)
  const hasAnalyticsAccess = useAppSelector(
    selectWebAppStateMySelfHasAnalyticsAccess,
  )
  useEffect(() => {
    if (mySelf && !hasAnalyticsAccess) {
      dispatch(setShowSnackBar(t('webApp.notAccessToFeature')))
      navigate(buildRelativePath([PathConfig.BOOKMETRIE]))
    }
  }, [mySelf, hasAnalyticsAccess])

  const booksInput =
    useAppSelector(selectAnalyticDetailsStateAnalyticDetailsBooksInput) || []
  const booksOutput = useAppSelector(
    selectAnalyticDetailsStateAnalyticDetailsBooksOutput,
  )

  function manageNavigatePrevious() {
    if (!firstEanSearch) {
      if (reportId === 'new') {
        setFirstEanSearch(searchParams.get('eans'))
      } else if (analyticDetails?.inputs[0]?.values) {
        setFirstEanSearch(analyticDetails.inputs[0].values[0])
      }
    } else if (firstEanSearch !== searchParams.get('eans')) {
      setHasNavigatePrevious(!!searchParams.get('eans'))
    } else {
      setHasNavigatePrevious(false)
    }

    if (!searchParams.get('eans')) {
      setFirstEanSearch(null)
    }
  }

  useEffect(() => {
    setReportId(urlReportId as string)
  }, [urlReportId])

  // Au chargement de la page
  useEffect(() => {
    if (hasAnalyticsAccess) {
      if (reportId === 'new' && searchParams.get('type')) {
        const analyticType = searchParams.get('type') as AnalyticsTypeEnum
        dispatch(analyticsDetailV2StateNewAnalyticAction({ analyticType }))
          .then(() => {
            if (searchParams.get('eans')) {
              const eanIds = `${searchParams.get('eans')}`
                .split(',')
                .map((ean: string) => Number(ean))
              void dispatch(
                analyticDetailsV2StateNewConstellationGetBookRecoV3Action({
                  eanIds,
                }),
              )
            }
          })
          .catch(() => {})
          .finally(() => {})
      }
      if (
        validate(reportId) &&
        !searchParams.get('type') &&
        !searchParams.get('eans')
      ) {
        void dispatch(analyticsDetailV2StateGetReportByIdAction({ reportId }))
      }

      if (reportId === 'readership_profile') {
        void dispatch(analyticsDetailV2StateGetMockReadershipProfile())
      }

      manageNavigatePrevious()
    }

    return () => {
      dispatch(reinitializeAnalyticDetailsV2State())
    }
  }, [reportId, searchParams.get('type'), searchParams.get('eans')])

  const postAnalytic = (toExit: boolean) => {
    if (booksInput.length && booksOutput.length) {
      dispatch(analyticDetailsV2StatePostNewAnalyticsAction())
        .then(() => {
          if (toExit) {
            navigate(
              buildRelativePath([PathConfig.BOOKMETRIE, PathConfig.ANALYTICS]),
            )
          }
          setSaveBeforeExitDialog(false)
          setRenameAnalyticDialog(false)
        })
        .finally(() => dispatch(resetPostNewAnalyticStatus()))
    }
  }

  const putAnalytic = (toExit: boolean) => {
    dispatch(analyticDetailsV2StatePutAnalyticDetailsByIdAction())
      .unwrap()
      .then(() => {
        if (toExit) {
          navigate(
            buildRelativePath([PathConfig.BOOKMETRIE, PathConfig.ANALYTICS]),
          )
        }
        setSaveBeforeExitDialog(false)
        setRenameAnalyticDialog(false)
      })
      .finally(() => dispatch(resetPutAnalyticStatus()))
  }

  const postAnalyticSuccess = useAppSelector(
    selectAnalyticDetailsStatePostNewAnalyticSuccess,
  )
  const postAnalyticError = useAppSelector(
    selectAnalyticDetailsStatePostNewAnalyticError,
  )
  const putAnalyticSuccess = useAppSelector(
    selectAnalyticDetailsStatePutAnalyticSuccess,
  )
  const putAnalyticError = useAppSelector(
    selectAnalyticDetailsStatePutAnalyticError,
  )

  const needSaveAnalyticDetails = useAppSelector(
    selectAnalyticDetailsStateNeedSaveAnalyticDetails,
  )

  const postPreviewError = useAppSelector(
    selectNewAnalyticsReadershipProfileStatePostPreviewError,
  )
  const postPreviewTypeError = useAppSelector(
    selectNewAnalyticsReadershipProfileStatePostPreviewTypeError,
  )

  useEffect(() => {
    if (postPreviewError && !displayPreviewErrorDialog) {
      setDisplayPreviewErrorDialog(true)
    }
  }, [postPreviewError])

  useEffect(() => {
    if ((putAnalyticSuccess || postAnalyticSuccess) && analyticDetails?.id) {
      void dispatch(
        setShowSnackBar(
          t('snackbarMessage.saveReportConfirm', {
            reportName: analyticDetails?.name || '',
          }),
        ),
      )
    }
    if (postAnalyticSuccess && analyticDetails?.id) {
      navigate(
        buildRelativePath([
          PathConfig.BOOKMETRIE,
          PathConfig.ANALYTICS,
          analyticDetails?.id,
        ]),
      )
    }

    if (putAnalyticError || postAnalyticError) {
      dispatch(setShowSnackBar(t('snackbarMessage.errorMessage')))
    }
  }, [
    analyticDetails,
    postAnalyticSuccess,
    postAnalyticError,
    putAnalyticError,
    putAnalyticSuccess,
    reportId,
  ])

  const handleChangeAnalyticName = (name: string) => {
    dispatch(analyticsDetailV2StateSetAnalyticDetailsName(name))
  }

  const handleSaveAnalytics = () => {
    setRenameAnalyticDialog(true)
  }
  const handleSaveAnalyticMain = (toExit = false) => {
    if (reportId === 'new') {
      void postAnalytic(toExit)
    } else {
      void putAnalytic(toExit)
    }
  }

  const handleRenameAnalytic = (newName: string) => {
    if (newName === analyticDetails.name) return
    patchReportService(analyticDetails.id, { name: newName })
      .then(() => {
        dispatch(
          setShowSnackBar(
            t('snackbarMessage.renameReportConfirm', {
              reportName: newName,
            }),
          ),
        )
      })
      .catch(() => {
        dispatch(setShowSnackBar(t('snackbarMessage.errorMessage')))
      })
  }

  const onValidateExitDialog = useCallback(() => {
    if (saveBeforeExitDialog) {
      navigate(buildRelativePath([PathConfig.BOOKMETRIE, PathConfig.ANALYTICS]))
    }
    setSaveBeforeExitDialog(false)
  }, [saveBeforeExitDialog])

  const onCloseExitDialog = useCallback(() => {
    setSaveBeforeExitDialog(false)
  }, [])

  return (
    <>
      <AnalyticDetailsLayout
        handleRenameAnalytic={handleRenameAnalytic}
        analyticName={analyticDetails?.name || ''}
        sharedBy={[
          analyticDetails?.shared_by?.first_name,
          analyticDetails?.shared_by?.last_name,
        ]
          .join(' ')
          .trim()}
        disableEdit={
          analyticDetails?.status !== AnalyticsStatusEnum.GENERATED ||
          analyticDetails.permission === AnalyticsPermissionEnum.READ
        }
        hasButtonPrevious={hasNavigatePrevious}
        isLoading={isLoadingGetAnalyticDetails}
        handleExitAnalyticDetails={() => {
          if (needSaveAnalyticDetails) {
            setSaveBeforeExitDialog(true)
          } else {
            return navigate(
              buildRelativePath([PathConfig.BOOKMETRIE, PathConfig.ANALYTICS]),
            )
          }
        }}
      >
        <SwitchViewAnalytic
          type={analyticDetailsType}
          handleSaveAnalytics={handleSaveAnalytics}
          handleShareAnalytics={() => setShareDialog(true)}
        />
      </AnalyticDetailsLayout>
      {postPreviewError && (
        <GlDialog
          open={displayPreviewErrorDialog}
          onClose={() => {
            setDisplayPreviewErrorDialog(false)
          }}
          backgroundImage={BackGroundPreviewTypeError}
        >
          <div
            className="flex flex-col gap-2 justify-end	"
            style={{
              width: 580,
              height: 312,
            }}
          >
            <GlDialogTitle
              label={
                (postPreviewTypeError ===
                  AnalyticPreviewTypeErrorEnum.SUBJECT_TOO_SMALL &&
                  t('NotEnoughDataOnSubject')) ||
                (postPreviewTypeError ===
                  AnalyticPreviewTypeErrorEnum.COMPARISON_TOO_SMALL &&
                  t('NotEnoughDataOnComparison')) ||
                ''
              }
            />
            <GlDialogBody color="#252525">
              <div>
                {postPreviewTypeError ===
                  AnalyticPreviewTypeErrorEnum.SUBJECT_TOO_SMALL &&
                  t('SubjectTooSmall')}
                {postPreviewTypeError ===
                  AnalyticPreviewTypeErrorEnum.COMPARISON_TOO_SMALL &&
                  t('ComparisonTooSmall')}
              </div>
              <div>{t('MayChangeSearch')}</div>
            </GlDialogBody>
            <div className="flex flex-row justify-between items-center gap-4">
              <GLDialogButtonStyle
                type="button"
                className="relative bg-[#252525] flex-1 border-[#252525] mt-6 hover:opacity-60 active:opacity-100"
                primary
                onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                  event.preventDefault()
                  setDisplayPreviewErrorDialog(false)
                }}
              >
                <span className="text-[#ffffff] font-medium">
                  {t('commons.buttons.close')}
                </span>
              </GLDialogButtonStyle>
            </div>
          </div>
        </GlDialog>
      )}
      {(analyticDetailsType === AnalyticsTypeEnum.READERSHIP_PROFILE && (
        <AnalyticsDialogExit
          open={saveBeforeExitDialog}
          onValidate={onValidateExitDialog}
          onClose={onCloseExitDialog}
        />
      )) || (
        <GlDialog
          open={renameAnalyticDialog || saveBeforeExitDialog}
          onClose={() => {
            setRenameAnalyticDialog(false)
            setSaveBeforeExitDialog(false)
          }}
        >
          <div
            className="flex flex-col gap-4"
            style={{
              width: 432,
            }}
          >
            <GlDialogTitle label={t('toSave')} />
            <GlDialogBody textAlignLeft color="#252525">
              {saveBeforeExitDialog && (
                <div>{t('wishSaveSearchBeforeToExit')}</div>
              )}
              <GlMatInputOutline
                type="text"
                onChangeValue={(value: string | number) =>
                  handleChangeAnalyticName(value as string)
                }
                label=""
                value={analyticDetails?.name || ''}
                placeholder={t('analyticNamePlaceHolder')}
                hasClearButton
                hasErrorMessage={t('analyticNameRequired')}
              />
            </GlDialogBody>
            <div className="flex flex-row justify-between items-center gap-4">
              <GLDialogButtonStyle
                type="button"
                className="hover:bg-[#f7f7f7] active:bg-[#e3e3e3] flex-1 flex flex-row items-center justify-center gap-2"
                onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                  event.preventDefault()
                  if (saveBeforeExitDialog) {
                    navigate(
                      buildRelativePath([
                        PathConfig.BOOKMETRIE,
                        PathConfig.ANALYTICS,
                      ]),
                    )
                  }
                  setRenameAnalyticDialog(false)
                  setSaveBeforeExitDialog(false)
                }}
              >
                {(saveBeforeExitDialog && (
                  <span>{t('commons.buttons.exit')}</span>
                )) || <span>{t('commons.buttons.cancel')}</span>}
              </GLDialogButtonStyle>
              <GLDialogButtonStyle
                type="button"
                className="relative text-[#ffffff] bg-[#252525] flex-1 border-[#252525] hover:opacity-60 active:opacity-100"
                primary
                onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                  event.preventDefault()
                  handleSaveAnalyticMain(saveBeforeExitDialog)
                }}
                isLoading={isLoadingPostNewAnalytic || isLoadingPutAnalytic}
                disabled={!analyticDetails?.name}
              >
                {((isLoadingPostNewAnalytic || isLoadingPutAnalytic) && (
                  <div className="absolute w-full h-full top-0 left-0 justify-center items-center">
                    <GlSpinner />
                  </div>
                )) ||
                  null}
                {t('commons.buttons.save')}
              </GLDialogButtonStyle>
            </div>
          </div>
        </GlDialog>
      )}
      <GlDialog open={shareDialog} onClose={() => setShareDialog(false)}>
        {analyticDetails && (
          <AnalyticsDashboardShareDialog
            analyticsName={analyticDetails?.name}
            analyticsId={analyticDetails?.id}
            owner={analyticDetails?.owner}
            companyUserId={companyUserId}
            slug={analyticDetails?.slug}
            analyticsType={analyticDetailsType}
          />
        )}
      </GlDialog>
    </>
  )
}
export default AnalyticDetailsView
