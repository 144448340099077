import { AxiosResponse, CancelToken } from 'axios'
import apiInstance from '../../backend-api.setup'
import { ApiPathConfig } from '../../../config/ApiPathConfig'

const getBookRecoV2Service = (
  params: { eanIds: number[]; nbrecos?: number },
  cancelToken?: CancelToken,
): Promise<any> =>
  apiInstance.get(ApiPathConfig.BOOK_RECO_V2_WITH_EANS(params), { cancelToken })

export default getBookRecoV2Service
