import { createAsyncThunk } from '@reduxjs/toolkit'
import { getReportByIdService } from '../../../core/api/services/bookmetrie/analytics/get-report-by-id.service'
import {
  ANALYTICS_DETAILS_V2_PATCH_USER_PERMISSION_TO_ANALYTIC_ACTION,
  ANALYTICS_DETAILS_V2_STATE_ADD_BOOK_INPUT_AND_GET_BOOK_RECO_V3_ACTION,
  ANALYTICS_DETAILS_V2_STATE_BOOK_DETAILS_GET_BOOK_RECO_ACTION,
  ANALYTICS_DETAILS_V2_STATE_DELETE_DATASET_MODULES,
  ANALYTICS_DETAILS_V2_STATE_DELETE_IMAGE_OUTPUT,
  ANALYTICS_DETAILS_V2_STATE_DELETE_TEXT_BOXES_OUTPUT,
  ANALYTICS_DETAILS_V2_STATE_DELETE_TITLE_OUTPUT,
  ANALYTICS_DETAILS_V2_STATE_DELETE_USER_TO_SHARE_REPORT_ACTION,
  ANALYTICS_DETAILS_V2_STATE_GET_AVAILABLE_MODULES,
  ANALYTICS_DETAILS_V2_STATE_GET_B2B_BOOK_BY_EAN_ID_ACTION,
  ANALYTICS_DETAILS_V2_STATE_GET_BOOK_DETAIL_DATA_ACTION,
  ANALYTICS_DETAILS_V2_STATE_GET_BOOK_DETAIL_DATA_BY_EAN_ID_ACTION,
  ANALYTICS_DETAILS_V2_STATE_GET_BOOK_RECO_V2_ACTION,
  ANALYTICS_DETAILS_V2_STATE_GET_DATASET_MODULES_PREVIEW,
  ANALYTICS_DETAILS_V2_STATE_GET_EDITIONS_BY_EAN_ID_ACTION,
  ANALYTICS_DETAILS_V2_STATE_GET_REPORT_DETAILS_BY_ID_ACTION,
  ANALYTICS_DETAILS_V2_STATE_NEW_ANALYTIC_ACTION,
  ANALYTICS_DETAILS_V2_STATE_NEW_CONSTELLATION_GET_BOOK_RECO_V3_ACTION,
  ANALYTICS_DETAILS_V2_STATE_PATCH_REPORT_BY_ID_CHANGE_OWNER_ACTION,
  ANALYTICS_DETAILS_V2_STATE_POST_DATASET_MODULES,
  ANALYTICS_DETAILS_V2_STATE_POST_IMAGE_OUTPUT,
  ANALYTICS_DETAILS_V2_STATE_POST_NEW_ANALYTIC_ACTION,
  ANALYTICS_DETAILS_V2_STATE_POST_TEXT_BOXES_OUTPUT,
  ANALYTICS_DETAILS_V2_STATE_POST_TITLE_OUTPUT,
  ANALYTICS_DETAILS_V2_STATE_PUT_DATASET_MODULES,
  ANALYTICS_DETAILS_V2_STATE_PUT_DATASET_MODULES_ORDER,
  ANALYTICS_DETAILS_V2_STATE_PUT_IMAGE_OUTPUT,
  ANALYTICS_DETAILS_V2_STATE_PUT_OUTPUTS_ORDER,
  ANALYTICS_DETAILS_V2_STATE_PUT_PERMISSIONS_BY_REPORT_ID_AND_USER_ID,
  ANALYTICS_DETAILS_V2_STATE_PUT_PREVIEW,
  ANALYTICS_DETAILS_V2_STATE_PUT_VISUAL_THEME,
  ANALYTICS_DETAILS_V2_STATE_PUT_REPORT_DETAILS_BY_ID_ACTION,
  ANALYTICS_DETAILS_V2_STATE_PUT_TEXT_BOXES_OUTPUT,
  ANALYTICS_DETAILS_V2_STATE_PUT_TITLE_OUTPUT,
  ANALYTICS_DETAILS_V2_STATE_REMOVE_BOOK_INPUT_AND_GET_BOOK_RECO_V3_ACTION,
  ANALYTICS_DETAILS_V2_STATE_REPLACE_DATASET_MODULES,
  ANALYTICS_DETAILS_V2_STATE_POST_STUDY_OBJECT_OUTPUT,
  ANALYTICS_DETAILS_V2_STATE_DELETE_STUDY_OBJECT_OUTPUT,
  ANALYTICS_DETAILS_V2_STATE_GET_AUTHOR_DETAIL_DATA_BY_ID_ACTION,
  ANALYTICS_DETAILS_V2_STATE_GET_AUTHOR_DETAIL_DATA_BY_BOOK_EAN_ACTION,
} from '../../prefix-actions.config'
import { RootState } from '../../store'
import { putReportByIdService } from '../../../core/api/services/bookmetrie/analytics/put-report-by-id.service'
import { WM_REPORT_SIZE_BOOKS_OUTPUT } from '../../../core/config/reports/report-details.config'
import { PermissionEnum } from '../../../core/enums/permission.enum'
import { putPermissionReportToUserService } from '../../../core/api/services/bookmetrie/share/put-permission-report-to-user.service'
import { deleteUserToShareReportService } from '../../../core/api/services/bookmetrie/share/delete-user-to-share-report.service'
import { getBookByIdEditionsService } from '../../../core/api/services/bookmetrie/get-book-by-id-editions.service'
import { AnalyticsTypeEnum } from '../../../core/enums/analytics-type.enum'
import { NewAnalyticDetailsModel } from '../../../core/model/new-analytic-details.model'
import getBookRecoV2Service from '../../../core/api/services/bookmetrie/get-book-reco-v2.service'
import { AnalyticInputInterface } from '../../../core/interface/analytics/analytic-input.interface'
import { AnalyticInputTypeEnum } from '../../../core/enums/analytic-input-type.enum'
import postNewReportService from '../../../core/api/services/bookmetrie/analytics/post-new-report.service'
import { WorkMirrorBookInterface } from '../../../core/interface/report/work-mirror-book.interface'
import { getB2bBookDetailsByEanIdService } from '../../../core/api/services/others/get-b2b-book-details-by-ean-id.service'
import getBookRecoService from '../../../core/api/services/bookmetrie/get-book-reco.service'
import { patchUserPermissionToShareReportService } from '../../../core/api/services/bookmetrie/share/patch-user-permission-to-share-report.service'
import { patchReportChangeOwnerService } from '../../../core/api/services/bookmetrie/share/patch-report-change-owner.service'
import getBookRecoV3Service from '../../../core/api/services/bookmetrie/get-book-reco-v3.service'
import { MockAnalyticsReadershipProfile } from '../../../core/model/mock-analytics-readership-profile'
import { getBookByEanIdService } from '../../../core/api/services/bookmetrie/get-book-by-ean-id.service'
import {
  AnalyticOutputInterface,
  DatasetOutputInterface,
} from '../../../core/interface/analytics/analytic-output.interface'
import { putOutputsOrderService } from '../../../core/api/services/bookmetrie/analytics/put-outputs-order.service'
import { putDatasetModulesOrderService } from '../../../core/api/services/bookmetrie/analytics/put-dataset-modules-order.service'
import { getAvailableModulesService } from '../../../core/api/services/bookmetrie/analytics/get-available-modules.service'
import { AnalyticOutputLayoutSizeEnum } from '../../../core/enums/analytic-output-layout-size.enum'
import { postTitleOutputService } from '../../../core/api/services/bookmetrie/analytics/add-module/post-title-output.service'
import { postTextBoxesOutputService } from '../../../core/api/services/bookmetrie/analytics/add-module/post-text-boxes-output.service'
import { postImageOutputService } from '../../../core/api/services/bookmetrie/analytics/add-module/post-image-output.service'
import { putTitleOutputService } from '../../../core/api/services/bookmetrie/analytics/add-module/put-title-output.service'
import { deleteTitleOutputService } from '../../../core/api/services/bookmetrie/analytics/add-module/delete-title-output.service'
import { putTextBoxesOutputService } from '../../../core/api/services/bookmetrie/analytics/add-module/put-text-boxes-output.service'
import { putImageOutputService } from '../../../core/api/services/bookmetrie/analytics/add-module/put-image-output.service'
import { postDatasetModulesService } from '../../../core/api/services/bookmetrie/analytics/add-module/post-dataset-modules.service'
import { DatasetModuleInterface } from '../../../core/interface/analytics/dataset-module.interface'
import { putDatasetModulesService } from '../../../core/api/services/bookmetrie/analytics/add-module/put-dataset-modules.service'
import { deleteDatasetModulesService } from '../../../core/api/services/bookmetrie/analytics/add-module/delete-dataset-modules.service'
import { DatasetModuleAvailableModulesInterface } from '../../../core/interface/analytics/analytic-available-modules.interface'
import { AnalyticOutputTypeEnum } from '../../../core/enums/analytic-output-type.enum'
import { putReadershipProfilePreviewService } from '../../../core/api/services/bookmetrie/analytics/put-readership-profile-preview.service'
import { deleteImageOutputService } from '../../../core/api/services/bookmetrie/analytics/add-module/delete-image-output.service'
import { deleteTextBoxesOutputService } from '../../../core/api/services/bookmetrie/analytics/add-module/delete-text-boxes-output.service'
import { AnalyticVisualThemeEnum } from '../../../core/enums/analytic-visual-theme.enum'
import { putVisualTheme } from '../../../core/api/services/bookmetrie/analytics/put-visual-theme.service'
import { postStudyObjectOutputService } from '../../../core/api/services/bookmetrie/analytics/add-module/post-study-object-output.service'
import { deleteStudyObjectOutputService } from '../../../core/api/services/bookmetrie/analytics/add-module/delete-study-object-output.service'
import { getAuthorByIdService } from '../../../core/api/services/bookmetrie/get-author-by-id.service'
import { getAuthorByBookEanService } from '../../../core/api/services/bookmetrie/get-author-by-book-ean.service'

export const analyticsDetailV2StateGetReportByIdAction = createAsyncThunk(
  ANALYTICS_DETAILS_V2_STATE_GET_REPORT_DETAILS_BY_ID_ACTION,
  async ({ reportId }: { reportId: string }, { getState }): Promise<any> => {
    try {
      const { cancelTokenGetAnalytics } = (getState() as RootState)
        .analyticDetailsV2State
      const { data } = await getReportByIdService(
        reportId,
        cancelTokenGetAnalytics?.token,
      )
      return data
    } catch (error) {
      throw new Error()
    }
  },
)

export const analyticsDetailV2StateGetMockReadershipProfile = createAsyncThunk(
  'mock-get-analytics-readership-profile',
  async (): Promise<any> => {
    try {
      return await new Promise((resolve) => {
        setTimeout(() => {
          resolve(new MockAnalyticsReadershipProfile())
        }, 1000)
      })
    } catch (error) {
      throw new Error()
    }
  },
)

export const analyticsDetailV2StateNewAnalyticAction = createAsyncThunk(
  ANALYTICS_DETAILS_V2_STATE_NEW_ANALYTIC_ACTION,
  async ({
    analyticType,
  }: {
    analyticType: AnalyticsTypeEnum
  }): Promise<any> => {
    try {
      return await new Promise((resolve) => {
        resolve(new NewAnalyticDetailsModel(analyticType))
      })
    } catch (error) {
      throw new Error()
    }
  },
)

export const analyticDetailsV2StatePutAnalyticDetailsByIdAction =
  createAsyncThunk(
    ANALYTICS_DETAILS_V2_STATE_PUT_REPORT_DETAILS_BY_ID_ACTION,
    async (_, { getState }): Promise<any> => {
      try {
        const { analyticDetailsV2State } = getState() as RootState
        const { analyticDetails, cancelTokenPutAnalytic } =
          analyticDetailsV2State

        const { data } = await putReportByIdService(
          analyticDetails,
          cancelTokenPutAnalytic?.token,
        )
        return data
      } catch (error) {
        throw new Error()
      }
    },
  )

export const analyticDetailsV2StateGetBookRecoV2Action = createAsyncThunk(
  ANALYTICS_DETAILS_V2_STATE_GET_BOOK_RECO_V2_ACTION,
  async (_, { getState }): Promise<any> => {
    try {
      const { analyticDetailsV2State } = getState() as RootState
      const { analyticDetails, cancelTokenGetBookRecoV2 } =
        analyticDetailsV2State

      const booksInputValues =
        analyticDetails?.inputs.find(
          (input: AnalyticInputInterface) =>
            input.type === AnalyticInputTypeEnum.BOOKS,
        ).values || []

      const eanIds = booksInputValues.filter((eanId: any) => eanId !== null)
      if (eanIds.length) {
        const { data } = await getBookRecoV2Service(
          {
            eanIds,
            nbrecos: WM_REPORT_SIZE_BOOKS_OUTPUT,
          },
          cancelTokenGetBookRecoV2?.token,
        )
        return data
      }
      return {
        type: AnalyticInputTypeEnum.BOOKS,
        values: [],
        outputs: [],
        books: [],
      }
    } catch (error) {
      throw new Error()
    }
  },
)

export const analyticDetailsV2StateNewConstellationGetBookRecoV3Action =
  createAsyncThunk(
    ANALYTICS_DETAILS_V2_STATE_NEW_CONSTELLATION_GET_BOOK_RECO_V3_ACTION,
    async ({ eanIds }: { eanIds: number[] }, { getState }): Promise<any> => {
      try {
        const { analyticDetailsV2State } = getState() as RootState
        const { cancelTokenGetBookRecoV3 } = analyticDetailsV2State

        if (eanIds.length) {
          const { data } = await getBookRecoV3Service(
            {
              eanIds,
              nbrecos: WM_REPORT_SIZE_BOOKS_OUTPUT,
            },
            cancelTokenGetBookRecoV3?.token,
          )
          return data
        }
        return {
          type: AnalyticInputTypeEnum.BOOKS,
          values: [],
          outputs: [],
          books: [],
        }
      } catch (error) {
        throw new Error()
      }
    },
  )

export const analyticDetailsV2StateAddBookInputAndGetBookRecoV3Action =
  createAsyncThunk(
    ANALYTICS_DETAILS_V2_STATE_ADD_BOOK_INPUT_AND_GET_BOOK_RECO_V3_ACTION,
    async ({ eanId }: { eanId: number }, { getState }): Promise<any> => {
      try {
        const { analyticDetailsV2State } = getState() as RootState
        const { analyticDetails, cancelTokenGetBookRecoV3 } =
          analyticDetailsV2State

        const booksInputValues =
          analyticDetails?.inputs.find(
            (input: AnalyticInputInterface) =>
              input.type === AnalyticInputTypeEnum.BOOKS,
          ).values || []

        const eanIds = [
          ...booksInputValues.filter((value: number) => value !== eanId),
          eanId,
        ]
        if (eanIds.length) {
          const { data } = await getBookRecoV3Service(
            {
              eanIds,
              nbrecos: WM_REPORT_SIZE_BOOKS_OUTPUT,
            },
            cancelTokenGetBookRecoV3?.token,
          )
          return data
        }
        return {
          type: AnalyticInputTypeEnum.BOOKS,
          values: [],
          outputs: [],
          books: [],
        }
      } catch (error) {
        throw new Error()
      }
    },
  )

export const analyticDetailsV2StateRemoveBookInputAndGetBookRecoV3Action =
  createAsyncThunk(
    ANALYTICS_DETAILS_V2_STATE_REMOVE_BOOK_INPUT_AND_GET_BOOK_RECO_V3_ACTION,
    async ({ eanId }: { eanId: number }, { getState }): Promise<any> => {
      try {
        const { analyticDetailsV2State } = getState() as RootState
        const { analyticDetails, cancelTokenGetBookRecoV3 } =
          analyticDetailsV2State

        const booksInputValues =
          analyticDetails?.inputs.find(
            (input: AnalyticInputInterface) =>
              input.type === AnalyticInputTypeEnum.BOOKS,
          ).values || []

        const eanIds = [
          ...booksInputValues.filter(
            (value: number) => `${value}` !== `${eanId}`,
          ),
        ]
        if (eanIds.length) {
          const { data } = await getBookRecoV3Service(
            {
              eanIds,
              nbrecos: WM_REPORT_SIZE_BOOKS_OUTPUT,
            },
            cancelTokenGetBookRecoV3?.token,
          )
          return data
        }
        return {
          type: AnalyticInputTypeEnum.BOOKS,
          values: [],
          outputs: [],
          books: [],
        }
      } catch (error) {
        throw new Error()
      }
    },
  )

export const analyticDetailsV2StatePutPermissionByReportIdAndUserId =
  createAsyncThunk(
    ANALYTICS_DETAILS_V2_STATE_PUT_PERMISSIONS_BY_REPORT_ID_AND_USER_ID,
    async (
      {
        reportId,
        userId,
        permission,
      }: {
        reportId: string
        userId: string
        permission: PermissionEnum
      },
      { getState },
    ): Promise<any> => {
      try {
        const { analyticDetailsV2State } = getState() as RootState
        const { cancelTokenPutUserPermissionToAnalytic } =
          analyticDetailsV2State

        const { data } = await putPermissionReportToUserService(
          reportId,
          userId,
          permission,
          cancelTokenPutUserPermissionToAnalytic?.token,
        )
        return data
      } catch (error) {
        throw new Error()
      }
    },
  )

export const analyticDetailsV2StateGetEditionsByEanIdAction = createAsyncThunk(
  ANALYTICS_DETAILS_V2_STATE_GET_EDITIONS_BY_EAN_ID_ACTION,
  async (eanId: number, { getState }): Promise<any> => {
    try {
      const { analyticDetailsV2State } = getState() as RootState
      const { cancelTokenGetEditions } = analyticDetailsV2State

      const { data } = await getBookByIdEditionsService(
        eanId,
        cancelTokenGetEditions?.token,
      )

      return data
    } catch (error) {
      throw new Error('')
    }
  },
)

export const analyticDetailsV2StateGetB2BBookByEanId = createAsyncThunk(
  ANALYTICS_DETAILS_V2_STATE_GET_B2B_BOOK_BY_EAN_ID_ACTION,
  async (eanId: number, { getState }): Promise<any> => {
    try {
      const { analyticDetailsV2State } = getState() as RootState
      const { cancelTokenGetB2BBookDetails } = analyticDetailsV2State

      const { data } = await getB2bBookDetailsByEanIdService(
        eanId,
        cancelTokenGetB2BBookDetails?.token,
      )

      return data
    } catch (error) {
      throw new Error('')
    }
  },
)

export const analyticDetailsV2StateBookDetailsGetBookRecoAction =
  createAsyncThunk(
    ANALYTICS_DETAILS_V2_STATE_BOOK_DETAILS_GET_BOOK_RECO_ACTION,
    async (eanId: number, { getState }): Promise<any> => {
      try {
        const { analyticDetailsV2State } = getState() as RootState
        const { cancelTokenBookDetailsGetBookReco } = analyticDetailsV2State

        const { data } = await getBookRecoService(
          {
            eanIds: [eanId],
            nbrecos: 10,
          },
          cancelTokenBookDetailsGetBookReco?.token,
        )

        return data
      } catch (error) {
        throw new Error('')
      }
    },
  )

export const analyticDetailsV2StateGetBookDetailsData = createAsyncThunk(
  ANALYTICS_DETAILS_V2_STATE_GET_BOOK_DETAIL_DATA_ACTION,
  (
    { bookDetails }: { bookDetails: WorkMirrorBookInterface | null },
    { dispatch },
  ): WorkMirrorBookInterface | null => {
    try {
      if (bookDetails) {
        void dispatch(
          analyticDetailsV2StateGetEditionsByEanIdAction(
            bookDetails.book.id_ean,
          ),
        )
        void dispatch(
          analyticDetailsV2StateGetB2BBookByEanId(bookDetails.book.id_ean),
        )
        void dispatch(
          analyticDetailsV2StateBookDetailsGetBookRecoAction(
            bookDetails.book.id_ean,
          ),
        )
      }

      return bookDetails
    } catch (error) {
      throw new Error('')
    }
  },
)

export const analyticDetailsV2StateGetBookDetailsDataById = createAsyncThunk(
  ANALYTICS_DETAILS_V2_STATE_GET_BOOK_DETAIL_DATA_BY_EAN_ID_ACTION,
  async (
    { eanId }: { eanId: number | null },
    { dispatch, getState },
  ): Promise<void> => {
    if (eanId) {
      try {
        const { analyticDetailsV2State } = getState() as RootState
        const { cancelTokenGetBookDetailById } = analyticDetailsV2State
        const { data } = await getBookByEanIdService(
          eanId,
          cancelTokenGetBookDetailById?.token,
        )
        void dispatch(
          analyticDetailsV2StateGetBookDetailsData({
            bookDetails: {
              book: data,
              id_ean: data.id_ean,
              popularity: 0,
            },
          }),
        )
      } catch (error) {
        throw new Error('')
      }
    } else {
      void dispatch(
        analyticDetailsV2StateGetBookDetailsData({ bookDetails: null }),
      )
    }
  },
)

export const analyticDetailsV2StateGetAuthorDetailsDataById = createAsyncThunk(
  ANALYTICS_DETAILS_V2_STATE_GET_AUTHOR_DETAIL_DATA_BY_ID_ACTION,
  async ({ authorId }: { authorId: string }): Promise<any> => {
    if (authorId) {
      try {
        const { data } = await getAuthorByIdService({ authorId })
        return data
      } catch (error) {
        throw new Error('')
      }
    }
  },
)

export const analyticDetailsV2StateGetAuthorDetailsDataByBookEan =
  createAsyncThunk(
    ANALYTICS_DETAILS_V2_STATE_GET_AUTHOR_DETAIL_DATA_BY_BOOK_EAN_ACTION,
    async ({
      authorName,
      ean,
    }: {
      authorName: string
      ean: number
    }): Promise<any> => {
      if (authorName && ean) {
        try {
          const { data } = await getAuthorByBookEanService({ authorName, ean })
          return data
        } catch (error) {
          throw new Error('')
        }
      }
    },
  )

export const analyticDetailsV2StatePostNewAnalyticsAction = createAsyncThunk(
  ANALYTICS_DETAILS_V2_STATE_POST_NEW_ANALYTIC_ACTION,
  async (_, { getState }): Promise<any> => {
    try {
      const { analyticDetailsV2State } = getState() as RootState
      const { analyticDetails, cancelTokenPostNewAnalytic } =
        analyticDetailsV2State

      if (analyticDetails.inputs[0].id === '') {
        delete analyticDetails.inputs[0].id
      }
      if (analyticDetails.inputs[0].outputs[0].id === '') {
        delete analyticDetails.inputs[0].outputs[0].id
      }

      const { data } = await postNewReportService(
        analyticDetails,
        cancelTokenPostNewAnalytic?.token,
      )

      return data
    } catch (error) {
      throw new Error('')
    }
  },
)

export const analyticDetailsV2StatePatchUserPermissionToAnalyticAction =
  createAsyncThunk(
    ANALYTICS_DETAILS_V2_PATCH_USER_PERMISSION_TO_ANALYTIC_ACTION,
    async (
      {
        reportId,
        newData,
      }: {
        reportId: string
        newData: {
          users: string[]
          permission: PermissionEnum
          message: string
        }
      },
      { getState },
    ): Promise<any> => {
      try {
        const { analyticDetailsV2State } = getState() as RootState
        const { cancelTokenPatchUserPermissionToAnalytic } =
          analyticDetailsV2State

        const { data } = await patchUserPermissionToShareReportService(
          reportId,
          newData,
          cancelTokenPatchUserPermissionToAnalytic?.token,
        )
        return data
      } catch (error) {
        throw new Error()
      }
    },
  )

export const analyticDetailsV2StateDeleteUserToShareReport = createAsyncThunk(
  ANALYTICS_DETAILS_V2_STATE_DELETE_USER_TO_SHARE_REPORT_ACTION,
  async (
    {
      reportId,
      userId,
    }: {
      reportId: string
      userId: string
    },
    { getState },
  ): Promise<any> => {
    try {
      const { analyticDetailsV2State } = getState() as RootState
      const { cancelTokenDeleteUserToShareReport } = analyticDetailsV2State

      const { data } = await deleteUserToShareReportService(
        reportId,
        userId,
        cancelTokenDeleteUserToShareReport?.token,
      )
      return data
    } catch (error) {
      throw new Error()
    }
  },
)

export const analyticDetailsV2StatePatchReportByIdChangeOwnerAction =
  createAsyncThunk(
    ANALYTICS_DETAILS_V2_STATE_PATCH_REPORT_BY_ID_CHANGE_OWNER_ACTION,
    async (
      {
        reportId,
        ownerId,
      }: {
        reportId: string
        ownerId: string
      },
      { getState },
    ): Promise<any> => {
      try {
        const { analyticDetailsV2State } = getState() as RootState
        const { cancelTokenPatchReportByIdChangeOwner } = analyticDetailsV2State
        const { data } = await patchReportChangeOwnerService(
          {
            reportId,
            ownerId,
          },
          cancelTokenPatchReportByIdChangeOwner?.token,
        )
        return data
      } catch (error) {
        throw new Error()
      }
    },
  )

export const analyticDetailsV2StatePutDatasetModulesOrderAction =
  createAsyncThunk(
    ANALYTICS_DETAILS_V2_STATE_PUT_DATASET_MODULES_ORDER,
    async (
      {
        outputId,
        datasetModules,
      }: {
        outputId: string
        datasetModules: DatasetModuleInterface[]
      },
      { getState },
    ): Promise<any> => {
      try {
        const { analyticDetailsV2State } = getState() as RootState
        const { cancelTokenPutDatasetModulesOrder } = analyticDetailsV2State

        const { data } = await putDatasetModulesOrderService(
          analyticDetailsV2State.analyticDetails.id,
          outputId,
          { dataset_modules: datasetModules.map((module) => module.id) },
          cancelTokenPutDatasetModulesOrder?.token,
        )
        return { outputId, data }
      } catch (error) {
        throw new Error()
      }
    },
  )

export const analyticDetailsV2StatePutOutputsOrderAction = createAsyncThunk(
  ANALYTICS_DETAILS_V2_STATE_PUT_OUTPUTS_ORDER,
  async (
    {
      outputs,
    }: {
      outputs: AnalyticOutputInterface[]
    },
    { getState },
  ): Promise<any> => {
    try {
      const { analyticDetailsV2State } = getState() as RootState
      const { cancelTokenPutOutputsModuleOrder } = analyticDetailsV2State

      const outputsIds = outputs.map(
        (output: AnalyticOutputInterface) => output.id,
      )

      await putOutputsOrderService(
        analyticDetailsV2State.analyticDetails.id,
        analyticDetailsV2State.analyticDetails.inputs[0].id,
        { outputs: outputsIds },
        cancelTokenPutOutputsModuleOrder?.token,
      )
      return outputs
    } catch (error) {
      throw new Error()
    }
  },
)

export const analyticDetailsV2StatePutVisualThemeAction = createAsyncThunk(
  ANALYTICS_DETAILS_V2_STATE_PUT_VISUAL_THEME,
  async (
    {
      visualTheme,
    }: {
      visualTheme: AnalyticVisualThemeEnum
    },
    { getState },
  ): Promise<any> => {
    try {
      const { analyticDetailsV2State } = getState() as RootState
      const { cancelTokenPutVisualTheme } = analyticDetailsV2State

      const { data } = await putVisualTheme(
        analyticDetailsV2State.analyticDetails.id,
        analyticDetailsV2State.analyticDetails.inputs[0].id,
        { visual_theme: visualTheme },
        cancelTokenPutVisualTheme?.token,
      )
      return data
    } catch (error) {
      throw new Error()
    }
  },
)

export const analyticsDetailsV2StateGetAvailableModulesAction =
  createAsyncThunk(
    ANALYTICS_DETAILS_V2_STATE_GET_AVAILABLE_MODULES,
    async (_, { getState }): Promise<any> => {
      try {
        const { analyticDetailsV2State } = getState() as RootState
        const { cancelTokenPostTitleOutput } = analyticDetailsV2State

        const { data } = await getAvailableModulesService(
          analyticDetailsV2State.analyticDetails.id,
          cancelTokenPostTitleOutput?.token,
        )
        return data
      } catch (error) {
        throw new Error()
      }
    },
  )

export const analyticsDetailsV2StatePostTitleOutputAction = createAsyncThunk(
  ANALYTICS_DETAILS_V2_STATE_POST_TITLE_OUTPUT,
  async (
    {
      titleObject,
    }: {
      titleObject: {
        text: string
        level: number
        layout_size: AnalyticOutputLayoutSizeEnum
      }
    },
    { getState },
  ): Promise<any> => {
    try {
      const { analyticDetailsV2State } = getState() as RootState
      const { cancelTokenPostTitleOutput } = analyticDetailsV2State

      const { data } = await postTitleOutputService(
        analyticDetailsV2State.analyticDetails.id,
        analyticDetailsV2State.analyticDetails.inputs[0].id,
        titleObject,
        cancelTokenPostTitleOutput?.token,
      )

      const newTitle = {
        id: data.id,
        type: AnalyticOutputTypeEnum.READERSHIP_PROFILE_TITLE,
        layout_size: data.layout_size,
        title: {
          level: data.level,
          text: data.text,
        },
        layout_size_config: data.layout_size_config,
      }

      return newTitle
    } catch (error) {
      throw new Error()
    }
  },
)

export const analyticsDetailsV2StatePutTitleOutputAction = createAsyncThunk(
  ANALYTICS_DETAILS_V2_STATE_PUT_TITLE_OUTPUT,
  async (
    {
      outputId,
      titleObject,
    }: {
      outputId: string
      titleObject: {
        text: string
        level: number
        layout_size: AnalyticOutputLayoutSizeEnum
      }
    },
    { getState },
  ): Promise<any> => {
    try {
      const { analyticDetailsV2State } = getState() as RootState
      const { cancelTokenPutTitleOutput } = analyticDetailsV2State

      const { data } = await putTitleOutputService(
        analyticDetailsV2State.analyticDetails.id,
        analyticDetailsV2State.analyticDetails.inputs[0].id,
        outputId,
        titleObject,
        cancelTokenPutTitleOutput?.token,
      )

      const updateTitle = {
        id: data.id,
        type: AnalyticOutputTypeEnum.READERSHIP_PROFILE_TITLE,
        layout_size: data.layout_size,
        title: {
          level: data.level,
          text: data.text,
        },
      }

      return updateTitle
    } catch (error) {
      throw new Error()
    }
  },
)

export const analyticsDetailsV2StateDeleteTitleOutputAction = createAsyncThunk(
  ANALYTICS_DETAILS_V2_STATE_DELETE_TITLE_OUTPUT,
  async (
    {
      outputId,
    }: {
      outputId: string
    },
    { getState },
  ): Promise<any> => {
    try {
      const { analyticDetailsV2State } = getState() as RootState
      const { cancelTokenDeleteTitleOutput } = analyticDetailsV2State
      await deleteTitleOutputService(
        analyticDetailsV2State.analyticDetails.id,
        analyticDetailsV2State.analyticDetails.inputs[0].id,
        outputId,
        cancelTokenDeleteTitleOutput?.token,
      )
      return outputId
    } catch (error) {
      throw new Error()
    }
  },
)

export const analyticsDetailsV2StatePostTextBoxesOutputAction =
  createAsyncThunk(
    ANALYTICS_DETAILS_V2_STATE_POST_TEXT_BOXES_OUTPUT,
    async (
      {
        textBoxesObject,
      }: {
        textBoxesObject: {
          title: string
          text: string
          layout_size: AnalyticOutputLayoutSizeEnum
        }
      },
      { getState },
    ): Promise<any> => {
      try {
        const { analyticDetailsV2State } = getState() as RootState
        const { cancelTokenPostTextBoxesOutput } = analyticDetailsV2State

        const { data } = await postTextBoxesOutputService(
          analyticDetailsV2State.analyticDetails.id,
          analyticDetailsV2State.analyticDetails.inputs[0].id,
          textBoxesObject,
          cancelTokenPostTextBoxesOutput?.token,
        )

        const newTextBoxes = {
          id: data.id,
          type: AnalyticOutputTypeEnum.READERSHIP_PROFILE_TEXTBOX,
          layout_size: data.layout_size,
          textbox: {
            title: data.title,
            text: data.text,
            use_dark_theme: data.use_dark_theme,
            text_size: data.text_size,
          },
          layout_size_config: data.layout_size_config,
        }

        return newTextBoxes
      } catch (error) {
        throw new Error()
      }
    },
  )

export const analyticsDetailsV2StatePutTextBoxesOutputAction = createAsyncThunk(
  ANALYTICS_DETAILS_V2_STATE_PUT_TEXT_BOXES_OUTPUT,
  async (
    {
      outputId,
      textBoxesObject,
    }: {
      outputId: string
      textBoxesObject: {
        title: string
        text: string
        layout_size: AnalyticOutputLayoutSizeEnum
        use_dark_theme: boolean
        text_size: number
      }
    },
    { getState },
  ): Promise<any> => {
    try {
      const { analyticDetailsV2State } = getState() as RootState
      const { cancelTokenPutTextBoxesOutput } = analyticDetailsV2State

      const { data } = await putTextBoxesOutputService(
        analyticDetailsV2State.analyticDetails.id,
        analyticDetailsV2State.analyticDetails.inputs[0].id,
        outputId,
        textBoxesObject,
        cancelTokenPutTextBoxesOutput?.token,
      )

      const updateTextBoxes = {
        id: data.id,
        type: AnalyticOutputTypeEnum.READERSHIP_PROFILE_TEXTBOX,
        layout_size: data.layout_size,
        layout_size_config: data.layout_size_config,
        textbox: {
          title: data.title,
          text: data.text,
          use_dark_theme: data.use_dark_theme,
          text_size: data.text_size,
        },
      }

      return updateTextBoxes
    } catch (error) {
      throw new Error()
    }
  },
)

export const analyticsDetailsV2StateDeleteTextBoxesOutputAction =
  createAsyncThunk(
    ANALYTICS_DETAILS_V2_STATE_DELETE_TEXT_BOXES_OUTPUT,
    async (
      {
        outputId,
      }: {
        outputId: string
      },
      { getState },
    ): Promise<any> => {
      try {
        const { analyticDetailsV2State } = getState() as RootState
        const { cancelTokenDeleteTextBoxesOutput } = analyticDetailsV2State
        await deleteTextBoxesOutputService(
          analyticDetailsV2State.analyticDetails.id,
          analyticDetailsV2State.analyticDetails.inputs[0].id,
          outputId,
          cancelTokenDeleteTextBoxesOutput?.token,
        )

        return outputId
      } catch (error) {
        throw new Error()
      }
    },
  )

export const analyticsDetailsV2StatePostImageOutputAction = createAsyncThunk(
  ANALYTICS_DETAILS_V2_STATE_POST_IMAGE_OUTPUT,
  async (
    {
      imageObject,
    }: {
      imageObject: FormData
    },
    { getState },
  ): Promise<any> => {
    try {
      const { analyticDetailsV2State } = getState() as RootState
      const { cancelTokenPostImageOutput } = analyticDetailsV2State

      const { data } = await postImageOutputService(
        analyticDetailsV2State.analyticDetails.id,
        analyticDetailsV2State.analyticDetails.inputs[0].id,
        imageObject,
        cancelTokenPostImageOutput?.token,
      )

      const newImage = {
        id: data.id,
        type: AnalyticOutputTypeEnum.READERSHIP_PROFILE_IMAGE,
        layout_size: data.layout_size,
        image: {
          card: '',
          image_mode: data.image_mode,
          landscape_4: data.landscape_4,
          landscape_6: data.landscape_6,
          landscape_8: data.landscape_8,
          landscape_12: data.landscape_12,
          portrait_4: data.portrait_4,
          square_4: data.square_4,
          square_6: data.square_6,
        },
        layout_size_config: data.layout_size_config,
      }

      return newImage
    } catch (error) {
      throw new Error()
    }
  },
)

export const analyticsDetailsV2StatePutImageOutputAction = createAsyncThunk(
  ANALYTICS_DETAILS_V2_STATE_PUT_IMAGE_OUTPUT,
  async (
    {
      outputId,
      imageObject,
    }: {
      outputId: string
      imageObject: FormData
    },
    { getState },
  ): Promise<any> => {
    try {
      const { analyticDetailsV2State } = getState() as RootState
      const { cancelTokenPutImageOutput } = analyticDetailsV2State

      const { data } = await putImageOutputService(
        analyticDetailsV2State.analyticDetails.id,
        analyticDetailsV2State.analyticDetails.inputs[0].id,
        outputId,
        imageObject,
        cancelTokenPutImageOutput?.token,
      )

      const newImage = {
        id: data.id,
        type: AnalyticOutputTypeEnum.READERSHIP_PROFILE_IMAGE,
        layout_size: data.layout_size,
        image: {
          card: data.card,
          image_mode: data.image_mode,
          landscape_4: data.landscape_4,
          landscape_6: data.landscape_6,
          landscape_8: data.landscape_8,
          landscape_12: data.landscape_12,
          portrait_4: data.portrait_4,
          square_4: data.square_4,
          square_6: data.square_6,
        },
        layout_size_config: data.layout_size_config,
      }

      return newImage
    } catch (error) {
      throw new Error()
    }
  },
)

export const analyticsDetailsV2StateDeleteImageOutputAction = createAsyncThunk(
  ANALYTICS_DETAILS_V2_STATE_DELETE_IMAGE_OUTPUT,
  async (
    {
      outputId,
    }: {
      outputId: string
    },
    { getState },
  ): Promise<any> => {
    try {
      const { analyticDetailsV2State } = getState() as RootState
      const { cancelTokenDeleteImageOutput } = analyticDetailsV2State
      await deleteImageOutputService(
        analyticDetailsV2State.analyticDetails.id,
        analyticDetailsV2State.analyticDetails.inputs[0].id,
        outputId,
        cancelTokenDeleteImageOutput?.token,
      )

      return outputId
    } catch (error) {
      throw new Error()
    }
  },
)

export const analyticsDetailsV2StatePostStudyObjectOutputAction =
  createAsyncThunk(
    ANALYTICS_DETAILS_V2_STATE_POST_STUDY_OBJECT_OUTPUT,
    async (
      layoutSize: AnalyticOutputLayoutSizeEnum,
      { getState },
    ): Promise<any> => {
      try {
        const { analyticDetailsV2State } = getState() as RootState

        const { data } = await postStudyObjectOutputService(
          analyticDetailsV2State.analyticDetails.id,
          analyticDetailsV2State.analyticDetails.inputs[0].id,
          layoutSize,
        )

        const newStudyObject = {
          id: data.id,
          type: AnalyticOutputTypeEnum.READERSHIP_PROFILE_STUDY_OBJECT,
          study_object: {
            subject_type: data.subject_type,
            subject_objects: data.subject_objects,
            comparison_type: data.comparison_type,
            comparison_objects: data.comparison_objects,
          },
          layout_size: data.layout_size,
          layout_size_config: data.layout_size_config,
        }

        return newStudyObject
      } catch (error) {
        throw new Error()
      }
    },
  )

export const analyticsDetailsV2StateDeleteStudyObjectOutputAction =
  createAsyncThunk(
    ANALYTICS_DETAILS_V2_STATE_DELETE_STUDY_OBJECT_OUTPUT,
    async (
      {
        outputId,
      }: {
        outputId: string
      },
      { getState },
    ): Promise<any> => {
      try {
        const { analyticDetailsV2State } = getState() as RootState
        const { cancelTokenDeleteStudyObjectOutput } = analyticDetailsV2State
        await deleteStudyObjectOutputService(
          analyticDetailsV2State.analyticDetails.id,
          analyticDetailsV2State.analyticDetails.inputs[0].id,
          outputId,
          cancelTokenDeleteStudyObjectOutput?.token,
        )
        return outputId
      } catch (error) {
        throw new Error()
      }
    },
  )

export const analyticsDetailsV2StatePostDatasetModulesAction = createAsyncThunk(
  ANALYTICS_DETAILS_V2_STATE_POST_DATASET_MODULES,
  async (
    {
      outputId,
      datasetModule,
      scrollToOutput = true,
    }: {
      outputId: string
      datasetModule:
        | DatasetModuleAvailableModulesInterface
        | DatasetModuleInterface
      scrollToOutput?: boolean
    },
    { getState },
  ): Promise<any> => {
    try {
      const { analyticDetailsV2State } = getState() as RootState
      const { cancelTokenPostDatasetModules } = analyticDetailsV2State

      const { data } = await postDatasetModulesService(
        analyticDetailsV2State.analyticDetails.id,
        outputId,
        datasetModule,
        cancelTokenPostDatasetModules?.token,
      )

      let output =
        analyticDetailsV2State.analyticDetails.inputs[0].outputs.find(
          (output: AnalyticOutputInterface) => output.id === outputId,
        )

      output = {
        ...output,
        dataset_output: {
          ...output.dataset_output,
          dataset_modules: [...output.dataset_output.dataset_modules, data],
        },
      }
      return { output, scrollToOutput }
    } catch (error) {
      throw new Error()
    }
  },
)

export const analyticsDetailsV2StateGetDatasetModulesPreviewAction =
  createAsyncThunk(
    ANALYTICS_DETAILS_V2_STATE_GET_DATASET_MODULES_PREVIEW,
    async (
      {
        outputId,
        datasetModule,
      }: {
        outputId: string
        datasetModule: DatasetModuleAvailableModulesInterface
      },
      { getState },
    ): Promise<any> => {
      try {
        const { analyticDetailsV2State } = getState() as RootState
        const { cancelTokenPostDatasetModules } = analyticDetailsV2State

        const { data } = await postDatasetModulesService(
          analyticDetailsV2State.analyticDetails.id,
          outputId,
          datasetModule,
          cancelTokenPostDatasetModules?.token,
        )

        return { ...data, id: datasetModule.id }
      } catch (error) {
        throw new Error()
      }
    },
  )

export const analyticsDetailsV2StatePutDatasetModulesAction = createAsyncThunk(
  ANALYTICS_DETAILS_V2_STATE_PUT_DATASET_MODULES,
  async (
    {
      datasetModule,
    }: {
      datasetModule: DatasetModuleInterface
    },
    { getState },
  ): Promise<any> => {
    try {
      const { analyticDetailsV2State } = getState() as RootState
      const { cancelTokenPutDatasetModules } = analyticDetailsV2State

      const outputId =
        analyticDetailsV2State.analyticDetails.inputs[0].outputs.find(
          (output: AnalyticOutputInterface) =>
            output.dataset_output?.dataset_modules.find(
              (module: DatasetModuleInterface) =>
                module.id === datasetModule.id,
            ),
        )?.id

      const { data } = await putDatasetModulesService(
        analyticDetailsV2State.analyticDetails.id,
        outputId,
        datasetModule,
        cancelTokenPutDatasetModules?.token,
      )
      let output =
        analyticDetailsV2State.analyticDetails.inputs[0].outputs.find(
          (output: AnalyticOutputInterface) => output.id === outputId,
        )
      const newDatasetModules = output.dataset_output.dataset_modules.map(
        (datasetModule: DatasetModuleInterface) => {
          if (datasetModule.id === data.id) {
            return data
          }
          return datasetModule
        },
      )
      output = {
        ...output,
        dataset_output: {
          ...output.dataset_output,
          dataset_modules: newDatasetModules,
        },
      }
      return output
    } catch (error) {
      throw new Error()
    }
  },
)

export const analyticsDetailsV2StateDeleteDatasetModulesAction =
  createAsyncThunk(
    ANALYTICS_DETAILS_V2_STATE_DELETE_DATASET_MODULES,
    async (
      {
        datasetModuleId,
      }: {
        datasetModuleId: string
      },
      { getState },
    ): Promise<any> => {
      try {
        const { analyticDetailsV2State } = getState() as RootState
        const { cancelTokenDeleteDatasetModules } = analyticDetailsV2State
        const outputId =
          analyticDetailsV2State.analyticDetails.inputs[0].outputs.find(
            (output: AnalyticOutputInterface) =>
              output.dataset_output?.dataset_modules.find(
                (module: DatasetModuleInterface) =>
                  module.id === datasetModuleId,
              ),
          )?.id
        await deleteDatasetModulesService(
          analyticDetailsV2State.analyticDetails.id,
          outputId,
          datasetModuleId,
          cancelTokenDeleteDatasetModules?.token,
        )

        let output =
          analyticDetailsV2State.analyticDetails.inputs[0].outputs.find(
            (output: AnalyticOutputInterface) => output.id === outputId,
          )
        const newDatasetModules = output.dataset_output.dataset_modules.filter(
          (datasetModule: DatasetModuleInterface) =>
            datasetModule.id !== datasetModuleId,
        )
        output = {
          ...output,
          dataset_output: {
            ...output.dataset_output,
            dataset_modules: newDatasetModules,
          },
        }

        return output
      } catch (error) {
        throw new Error()
      }
    },
  )

export const analyticsDetailsV2StateReplaceDatasetModulesAction =
  createAsyncThunk(
    ANALYTICS_DETAILS_V2_STATE_REPLACE_DATASET_MODULES,
    async (
      {
        outputId,
        outputIdToReplace,
        datasetModule,
      }: {
        outputId: string
        outputIdToReplace: string
        datasetModule:
          | DatasetModuleAvailableModulesInterface
          | DatasetModuleInterface
      },
      { getState, dispatch },
    ): Promise<any> => {
      try {
        const { analyticDetailsV2State } = getState() as RootState

        const data = await dispatch(
          analyticsDetailsV2StatePostDatasetModulesAction({
            outputId,
            datasetModule,
            scrollToOutput: false,
          }),
        )

        const output: DatasetOutputInterface =
          analyticDetailsV2State.analyticDetails.inputs[0].outputs.find(
            (output: AnalyticOutputInterface) => output.id === outputId,
          ).dataset_output

        const indexToReplace = output.dataset_modules.findIndex(
          (module: DatasetModuleInterface) => module.id === outputIdToReplace,
        )

        const newDatasetModules = [...output.dataset_modules]

        newDatasetModules.splice(
          indexToReplace,
          0,
          data.payload.output.dataset_output.dataset_modules.slice(-1)[0],
        )

        await dispatch(
          analyticDetailsV2StatePutDatasetModulesOrderAction({
            outputId,
            datasetModules: newDatasetModules,
          }),
        )

        void dispatch(
          analyticsDetailsV2StateDeleteDatasetModulesAction({
            datasetModuleId: outputIdToReplace,
          }),
        )

        return data.payload.output
      } catch (error) {
        throw new Error()
      }
    },
  )

export const analyticsDetailsV2StatePutPreviewAction = createAsyncThunk(
  ANALYTICS_DETAILS_V2_STATE_PUT_PREVIEW,
  async (
    {
      cardId,
      isVisible,
    }: {
      cardId: string
      isVisible: boolean
    },
    { getState },
  ): Promise<any> => {
    try {
      const { analyticDetailsV2State } = getState() as RootState
      const { cancelTokenPutPreview } = analyticDetailsV2State
      const { data } = await putReadershipProfilePreviewService(
        analyticDetailsV2State.analyticDetails.id,
        cardId,
        isVisible,
        cancelTokenPutPreview,
      )
      return data
    } catch (error) {
      throw new Error()
    }
  },
)
