import { AxiosResponse } from 'axios'
import apiInstance from '../../../backend-api.setup'
import { ApiPathConfig } from '../../../../config/ApiPathConfig'
import { AudienceInputInterface } from '../../../../enums/audience/audience-input.interface'
import { AnalyticDetailsInterface } from '../../../../interface/analytics/analytic-details.interface'

const getPrivateReportById = (
  id: string,
): Promise<AxiosResponse<AnalyticDetailsInterface<AudienceInputInterface>>> => {
  return apiInstance.get(ApiPathConfig.PRIVATE_REPORTS_BY_ID(id))
}

export default getPrivateReportById
